import { useState } from 'react';
import { Alert, BackButton, Blank, Label, PhoneNumberField, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { useFindPassword } from 'src/container/find-password';
import { FindPasswordSuccessPageV1 } from './FindPasswordSuccessPageV1';

// TODO 카카오톡 수신 확인 필요
export function FindPasswordPage() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const { isFindPasswordLoading, isSuccess, errorMessage, setErrorMessage, findPassword } = useFindPassword();

  const buttonDisabled = !email || phone.length != 11 || !name;

  if (isSuccess) {
    return <FindPasswordSuccessPageV1 />;
  }

  return (
    <>
      <TopNavbar
        title="비밀번호 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isFindPasswordLoading && <Blank />}
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Section>
        <Label.col>
          <Label.Text children="*아이디" />
          <TextInput
            placeholder="you@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value.replace(/[ •\t]/g, '').toLowerCase())}
          />
        </Label.col>
        <Label.col>
          <Label.Text children="*이름" />
          <TextInput
            placeholder="이름을 입력해주세요."
            value={name}
            onChange={(e) => setName(String(e.target.value).replace(/•/g, '').replace(/\t/g, ''))}
            onKeyDown={(e) => {
              const specialCharacters = /[~`!@#$%^&*()_+|<>?:/;'".,]/;
              const whitespaceCharacters = /[ ]/;
              if (specialCharacters.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Label.col>

        <Label.col>
          <Label.Text children="*전화번호" />
          <PhoneNumberField value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Label.col>

        <div className="text-gray-400">* 이메일 또는 알림톡으로 비밀번호 변경 안내 메시지를 보내드립니다. </div>
      </Section>
      <Section>
        <a target="_blank" rel="noreferrer" href="http://superstudy.channel.io/">
          <Button.lg children="문의하기" className="w-full" />
        </a>
        <Button.lg
          children="비밀번호 찾기"
          disabled={buttonDisabled}
          onClick={() => findPassword({ email, name, phone })}
          className="filled-primary"
        />
      </Section>
    </>
  );
}
