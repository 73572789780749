import { useRecoilValue } from 'recoil';
import { ErrorBlank } from 'src/components';
import { DocumentObjectComponent } from 'src/components/DocumentObjectComponent';
import { ImageObjectComponent } from 'src/components/ImageObjectComponent';
import { Blank, Label, Section, Select, Textarea } from 'src/components/common';
import { Checkbox } from 'src/components/common/Checkbox';
import { Button } from 'src/components/common/Button';
import { FeedsDetail } from 'src/components/common/FeedsDetail';
import { FileUpload } from 'src/components/common/FileUpload';
import { ImageUpload } from 'src/components/common/ImageUpload';
import { TextInput } from 'src/components/common/TextInput';
import { useTeacherNoticeAdd } from 'src/container/teacher-notice-add';
import { Code, Notice } from 'src/generated/model';
import { meState } from 'src/store';
import { DateFormat, DateUtil } from 'src/util/date';
import { getExtOfFilename } from 'src/util/file';

interface NoticeAddProps {
  noticeData?: Notice;
  categoryData?: Code[];
}

export function NoticeAddPage({ noticeData, categoryData }: NoticeAddProps) {
  const meRecoil = useRecoilValue(meState);

  const {
    notice,
    imageObjectMap,
    documentObjectMap,
    errorMessage,
    isLoading,
    submitButtonDisabled,
    toStudent,
    toParent,
    setNotice,
    handleImageAdd,
    handleDocumentAdd,
    toggleImageDelete,
    toggleDocumentDelete,
    handleSubmit,
    setToStudent,
    setToParent,
  } = useTeacherNoticeAdd(noticeData);

  const imageObjectMapPaths = (): string[] => {
    // imageObjectMap의 값들을 배열로 변환 후 filter와 map 함수를 사용하여 조건을 충족하는 imageObject의 image만 추출하여 string[]로 반환
    const pathsArray: string[] = Array.from(imageObjectMap.values())
      .filter((imageObject) => !imageObject.isDelete)
      .map((imageObject) => {
        if (typeof imageObject.image === 'string') {
          return imageObject.image;
        } else {
          return URL.createObjectURL(imageObject.image) + '?ext=' + getExtOfFilename(imageObject.image.name);
        }
      });

    return pathsArray;
  };

  const documentObjectMapPaths = (): string[] => {
    // imageObjectMap의 값들을 배열로 변환 후 filter와 map 함수를 사용하여 조건을 충족하는 imageObject의 image만 추출하여 string[]로 반환
    const pathsArray: string[] = Array.from(documentObjectMap.values())
      .filter((documentObject) => !documentObject.isDelete)
      .map((documentObject) => {
        if (typeof documentObject?.document === 'string') {
          return documentObject?.document;
        } else {
          return documentObject.document.name;
        }
      });

    return pathsArray;
  };

  return (
    <div className="md:flex md:flex-col lg:grid lg:grid-cols-2 lg:space-x-4">
      <>
        {isLoading && <Blank />}
        {errorMessage && <ErrorBlank text={errorMessage} />}
        <Section>
          <Label.col>
            <Label.Text>
              *<span className="text-red-500">(필수)</span> 구분
            </Label.Text>
            <Select.lg
              value={notice?.category}
              onChange={(e) => setNotice((prevState) => ({ ...prevState, category: e.target.value }) as Notice)}
            >
              <option selected hidden>
                구분을 선택해주세요
              </option>
              {categoryData?.map((el: Code) => (
                <option id={el.name} value={el.name} key={el.name}>
                  {el.name}
                </option>
              ))}
            </Select.lg>
          </Label.col>

          {/* 전달 대상 선택 */}
          <Label.Text>
            *<span className="text-red-500">(필수)</span> 전달 대상 선택
          </Label.Text>
          <>
            <div className="flex space-x-4 px-4">
              <Checkbox
                checked={toStudent}
                onChange={() => {
                  setToStudent(!toStudent);
                  if (toStudent && !toParent) {
                    setToParent(true);
                  }
                }}
              />
              <p>학생</p>
              <Checkbox
                checked={toParent}
                onChange={() => {
                  setToParent(!toParent);
                  if (!toStudent && toParent) {
                    setToStudent(true);
                  }
                }}
              />
              <p>보호자</p>
            </div>
          </>

          <Label.col>
            <Label.Text>
              *<span className="text-red-500">(필수)</span> 제목
            </Label.Text>
            <TextInput
              placeholder="제목을 입력해주세요"
              value={notice?.title}
              onChange={(e) => setNotice((prevState) => ({ ...prevState, title: e.target.value }) as Notice)}
            />
          </Label.col>

          <Label.col>
            <Label.Text>
              *<span className="text-red-500">(필수)</span> 내용
            </Label.Text>
            <Textarea
              placeholder="내용을 입력해주세요."
              value={notice?.content}
              onChange={(e) => setNotice((prevState) => ({ ...prevState, content: e.target.value }) as Notice)}
              className="border"
            />
          </Label.col>
          <div>
            <Label.Text children="이미지" />
            <div className="mt-1 grid w-full grid-flow-row grid-cols-3 gap-2">
              {[...imageObjectMap].map(([key, value]) => (
                <ImageObjectComponent
                  key={key}
                  id={key}
                  imageObjet={value}
                  onDeleteClick={toggleImageDelete}
                  cardType={true}
                />
              ))}
              <ImageUpload accept=".pdf, .png, .jpeg, .jpg" onChange={handleImageAdd} />
            </div>
          </div>
          <div>
            <Label.Text children="파일" />
            <FileUpload onChange={handleDocumentAdd} className="mt-1">
              {[...documentObjectMap].map(([key, value]) => (
                <DocumentObjectComponent
                  key={key}
                  id={key}
                  documentObjet={value}
                  onDeleteClick={toggleDocumentDelete}
                />
              ))}
            </FileUpload>
          </div>
        </Section>
      </>
      <div className="relative">
        <div className="scroll-box overflow-y-scroll py-4 lg:fixed lg:top-0 lg:max-h-screen-12">
          <div className="mb-3 text-lg font-bold">미리보기</div>
          <div className=" w-full rounded-lg border p-3">
            <FeedsDetail
              category1={notice?.category}
              category1Color="peach_orange"
              sendTo={(toStudent ? '학생' : '') + (toStudent && toParent ? '/' : '') + (toParent ? '보호자' : '')}
              sendToColor="gray-100"
              title={notice?.title}
              contentText={notice?.content}
              contentImages={imageObjectMapPaths()}
              contentFiles={documentObjectMapPaths()}
              writer={meRecoil?.name}
              createAt={DateUtil.formatDate(new Date(), DateFormat['YYYY.MM.DD HH:mm'])}
            />
          </div>
          <div className="mt-2">
            <Button.lg
              children={notice?.id ? '수정하기' : '등록하기'}
              disabled={submitButtonDisabled}
              onClick={() => handleSubmit({ notice, imageObjectMap, documentObjectMap })}
              className="filled-primary mx-auto w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
