import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ReactComponent as Refresh } from 'src/assets/svg/refresh.svg';
import { NoticePopup } from 'src/components/NoticePopup';
import { BackButton, Blank, Label, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { TextInput } from 'src/components/common/TextInput';
import { UserContainer } from 'src/container/user';
import { isStayLoggedInState } from 'src/store';

export function Login() {
  const [isStayLoggedIn, setIsStayLoggedIn] = useRecoilState(isStayLoggedInState);
  const { handleLogin, errorMessage, errorCode } = UserContainer.useContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [blankOpen, setBlankOpen] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(true);

  const handleStayLoggedIn = () => {
    setIsStayLoggedIn((prevState) => !prevState);
  };

  return (
    <div className="box-border flex w-full items-center justify-center">
      <div className="w-full max-w-xl">
        {blankOpen && <Blank />}
        <TopNavbar
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
          title="로그인"
          right={
            <div
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
              className="text-sm text-brand-1"
            >
              <Refresh />
            </div>
          }
        />
        <Section>
          <Label.col>
            <Label.Text children="아이디" />
            <TextInput
              placeholder="아이디(이메일주소)를 입력해주세요."
              value={email}
              onChange={(e) => setEmail(e.target.value.replace(/[ •\t]/g, '').toLowerCase())}
            />
          </Label.col>

          <Label.col className="mt-6">
            <Label.Text children="비밀번호" />
            <TextInput
              type="password"
              placeholder="비밀번호를 입력해주세요."
              value={password}
              onChange={(e) =>
                setPassword(String(e.target.value).replace(/ /g, '').replace(/•/g, '').replace(/\t/g, ''))
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleLogin(email, password);
              }}
            />
          </Label.col>

          <div className="flex  justify-between">
            <div>
              <button onClick={handleStayLoggedIn} className="rounded-full border border-brand-1 px-4 py-2">
                <div className="flex space-x-3">
                  <Checkbox checked={isStayLoggedIn} onChange={handleStayLoggedIn} />
                  <Label.Text children="자동 로그인" />
                </div>
              </button>
            </div>
            <div className="flex  space-x-3 text-xs">
              <Link to="/find-id">
                <div className="text-gray-4 rounded-full border border-brand-1 px-4 py-2 ">아이디 찾기</div>
              </Link>
              <Link to="/find-password">
                <div className="text-gray-4 rounded-full border border-brand-1 px-4 py-2 ">비밀번호 찾기</div>
              </Link>
            </div>
          </div>
          <div className="text-red-600">{errorMessage}</div>

          {errorCode === '1001102' && (
            <Link to="/find-password">
              <div className="filled-primary rounded-lg border border-brand-1 px-4 py-2 text-center ">
                비밀번호 잠금해제
              </div>
            </Link>
          )}

          <Button.lg
            children="로그인"
            disabled={!email || !password}
            onClick={() => handleLogin(email, password)}
            className="filled-primary"
          />

          <Link to="/AboutSuperSchool">
            <Button.xl children="슈퍼스쿨 미리보기" className="outlined-primary w-full" />
          </Link>

          <div className="flex justify-center">
            <a href="https://superstudy.channel.io/lounge" target="blank">
              <div className="mt-5 rounded-full border border-brand-1 px-4 py-2 text-center">문의하기</div>
            </a>
          </div>
        </Section>

        {/* <div className="fixed bottom-3 left-0 right-0 w-full text-center text-gray-600">슈퍼스쿨</div> */}
      </div>
      <NoticePopup noticeOpen={noticeOpen} setNoticeClose={() => setNoticeOpen(false)} width="w-max" />
    </div>
  );
}
