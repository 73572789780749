import { debounce } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Checkbox, useCheckbox } from 'src/components/common/Checkbox';
import { Pagination } from 'src/components/common/Pagination';
import { SearchInput } from 'src/components/common/SearchInput';
import { teacherRoles } from 'src/constants/teacher-roles';
import {
  teacherManagementDeleteTeacher,
  teacherManagementGetTeachers,
  teacherManagementRequestSignUp,
  useTeacherManagementGetTeachers,
} from 'src/generated/endpoint';
import { ResponseTeacherInfoDto, Role } from 'src/generated/model';
import { useSearch } from 'src/lib/router';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { exportCSVToExcel } from 'src/util/download-excel';
import { AdminContext } from '../AdminMainPage';

export function TeacherPage() {
  const { t } = useTranslation();
  const { year } = useContext(AdminContext);
  const { page, size } = useSearch({ page: 1, size: 25 });
  const [role, setRole] = useState<Role>();
  const [firstVisit, setFirstVisit] = useState<boolean>();
  const [keyword, setKeyword] = useState('');

  const setToastMsg = useSetRecoilState(toastState);

  const { data: teachers } = useTeacherManagementGetTeachers(
    { page, size, year, role, first_visit: firstVisit, keyword },
    { query: { keepPreviousData: true } },
  );

  const idMap = teachers?.items.reduce((map: any, obj: any) => {
    map[obj.id] = obj;
    return map;
  }, {});

  // idMap의 값들을 배열로 변환합니다.
  const uniqueTeachers: ResponseTeacherInfoDto[] = idMap && Object.values(idMap);

  const cb = useCheckbox(uniqueTeachers);
  const ids = cb.items.map(({ id }) => id);

  async function requestSignUp() {
    if (!confirm(`사용자 ${cb.items.length}명에게 가입요청 메일을 보낼까요?`)) return;
    let sucCnt = 0;
    await Promise.all(
      ids.map((id) =>
        teacherManagementRequestSignUp(id).then((result) => {
          result && sucCnt++;
        }),
      ),
    );
    setToastMsg(`가입요청 메일 전송 성공 ${sucCnt}건 / 실패 ${cb.items.length - sucCnt}건`);
    cb.clear();
  }

  async function deleteTeacher() {
    if (!confirm(`항목 ${cb.items.length}개를 삭제할까요?`)) return;
    let sucCnt = 0;
    await Promise.all(
      ids.map((id) =>
        teacherManagementDeleteTeacher(id).then((result) => {
          result && sucCnt++;
        }),
      ),
    );
    setToastMsg(`항목 삭제 성공 ${sucCnt}건 / 실패 ${cb.items.length - sucCnt}건`);
    cb.clear();
  }

  async function downloadAsExcel() {
    const { items } = await teacherManagementGetTeachers({ size: 10000, year });
    const content =
      '역할,이름,이메일,전화번호,부서,직책\n' +
      items
        .map((item) =>
          [t(`Role.${item.role}`), item.name, item.email, item.phone, item.department, item.position].join(),
        )
        .join('\n');
    exportCSVToExcel(content, '선생님');
  }

  function onRoleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === 'undefined') return setRole(undefined);
    setRole(e.target.value as Role);
  }

  function onFirstVisitChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === 'undefined') return setFirstVisit(undefined);
    if (e.target.value === 'false') return setFirstVisit(false);
    if (e.target.value === 'true') return setFirstVisit(true);
  }

  const onKeywordChange = debounce((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value), 300);

  return (
    <Admin.Section>
      <Admin.H2>선생님</Admin.H2>

      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Select onChange={onRoleChange}>
            <option value="undefined">역할전체</option>
            {teacherRoles.map((role) => (
              <option key={role} value={role}>
                {t(`Role.${role}`)}
              </option>
            ))}
          </Select>
          <Select onChange={onFirstVisitChange}>
            <option value="undefined">가입여부</option>
            <option value="false">가입완료</option>
            <option value="true">가입대기</option>
          </Select>
        </div>
        <div className="flex gap-2">
          <SearchInput placeholder="검색" onChange={onKeywordChange} />
        </div>
      </div>

      <div className="flex gap-2">
        <Link children="추가" to={Routes.admin.teacher.new} className="button-sm outlined-gray" />
        <Link children="일괄 추가" to={Routes.admin.teacher.batch} className="button-sm outlined-gray" />
        <Button.sm
          children="가입요청 메일전송"
          title="가입여부 X 상태의 사용자만 가능합니다"
          disabled={cb.allUnchecked || cb.items.some((item) => !item.firstVisit)}
          onClick={requestSignUp}
          className="outlined-gray"
        />
        <Button.sm
          children="삭제"
          title="가입여부 X 상태의 사용자만 삭제 가능합니다"
          disabled={cb.allUnchecked || cb.items.some((item) => !item.firstVisit)}
          onClick={deleteTeacher}
          className="outlined-gray"
        />
        <Button.sm children="엑셀 다운로드" onClick={downloadAsExcel} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell>
              <Checkbox checked={cb.allChecked} onChange={cb.clickAll} />
            </Admin.TableHCell>
            <Admin.TableHCell children="역할" />
            <Admin.TableHCell children="이름" />
            <Admin.TableHCell children="이메일" />
            <Admin.TableHCell children="전화번호" />
            <Admin.TableHCell children="부서" />
            <Admin.TableHCell children="직책" />
            <Admin.TableHCell children="상태" />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {uniqueTeachers?.map((teacher, i) => (
            <Admin.TableRow key={teacher.id} to={`${Routes.admin.teacher.index}/${teacher.id}`}>
              <Admin.TableCell onClick={(e) => (e.stopPropagation(), cb.click(i))}>
                <Checkbox checked={cb.checked(i)} onChange={() => cb.click(i)} />
              </Admin.TableCell>
              <Admin.TableCell children={t(`Role.${teacher.role}`)} />
              <Admin.TableCell children={teacher.name} />
              <Admin.TableCell children={teacher.email} />
              <Admin.TableCell children={teacher.phone} />
              <Admin.TableCell children={teacher.department} />
              <Admin.TableCell children={teacher.position} />
              <Admin.TableCell children={teacher.firstVisit ? '미가입' : teacher.expiredReason || '재직'} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>

      <Pagination data={teachers} />
    </Admin.Section>
  );
}
