import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { ReactComponent as FileItemIcon } from 'src/assets/svg/file-item-icon.svg';
import { Constants } from 'src/constants';
import { Chat } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { getFileNameFromUrl } from 'src/util/file';
import { isSameMinute } from 'src/util/time';

interface SendMessageProps {
  PreMessageData?: Chat;
  MessageData: Chat;
  PostMessageData: Chat;
  userRole: 'student' | 'teacher';
}

export function SendMessage({ PreMessageData, MessageData, PostMessageData, userRole }: SendMessageProps) {
  const isFirst = PreMessageData?.senderId !== MessageData?.senderId;
  const isLast =
    PostMessageData?.senderId !== MessageData?.senderId ||
    !isSameMinute(MessageData?.createdAt || '', PostMessageData?.createdAt || '');

  const activitiesInContent = useMemo(
    () => [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/activityv3\/\d+/g)],
    [MessageData],
  );
  const boardsInContent = useMemo(
    () => [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/board\/\d+/g)],
    [MessageData],
  );
  const newslettersInContent = useMemo(
    () => [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/newsletter\/\d+/g)],
    [MessageData],
  );
  const noticesInContent = useMemo(
    () => [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/notice\/\d+/g)],
    [MessageData],
  );

  return (
    <div className={`message ${isFirst ? 'mt-2' : 'mt-1'} w-full ${isLast ? 'pl-3' : 'pl-20'}  pr-3 `}>
      <div className="flex w-full flex-row-reverse items-end px-2 ">
        <div className=" rounded-md bg-brand-1 p-1 px-6 text-white">
          <p className="feedback_space whitespace-pre-wrap break-all text-left text-sm">
            <Linkify>{MessageData?.content}</Linkify>
          </p>
        </div>
        {isLast && (
          <div className="pl-7 pr-2">
            <small className="text-gray-500">
              {MessageData?.createdAt && DateUtil.formatDate(MessageData?.createdAt, DateFormat['HH:mm'])}
            </small>
          </div>
        )}
      </div>
      {!!MessageData?.images?.length && (
        <div className="flex items-start justify-end space-x-2 pt-1">
          {MessageData.images.map((image: string, i: number) => (
            <div key={i} className="w-1/6">
              <div className="aspect-square rounded border border-neutral-200">
                <LazyLoadImage
                  src={`${Constants.imageUrl}${image}`}
                  alt=""
                  loading="lazy"
                  className="object-fit h-full w-full rounded"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {!!MessageData?.files?.length && (
        <div className="flex flex-col items-end gap-1 pt-1">
          {MessageData.files.map((fileUrl: string, index) => (
            <div key={index} className="flex h-8 w-max items-center space-x-2 rounded bg-stone-50 px-3 py-1">
              <FileItemIcon />
              <a
                className="ml-2 text-xs text-neutral-500"
                href={`${Constants.imageUrl}${fileUrl}`}
                target="_blank"
                rel="noreferrer"
                download={getFileNameFromUrl(fileUrl)}
              >
                {getFileNameFromUrl(fileUrl)}
              </a>
            </div>
          ))}
        </div>
      )}
      {!!activitiesInContent.length && (
        <div className="mt-1 flex flex-col items-end space-y-1">
          {activitiesInContent.map((el) => (
            <a
              href={el[0]}
              className="block w-min max-w-2/3 cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
            >
              {`https://web.dev.superschool.link/${userRole}/activityv3/` + el[0].split('/').pop()}
            </a>
          ))}
        </div>
      )}
      {!!boardsInContent.length && (
        <div className="mt-1 flex flex-col items-end space-y-1">
          {boardsInContent.map((el) => (
            <a
              href={el[0]}
              className="block w-min max-w-2/3 cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
            >
              {`https://web.dev.superschool.link/${userRole}/board/` + el[0].split('/').pop()}
            </a>
          ))}
        </div>
      )}
      {!!newslettersInContent.length && (
        <div className="mt-1 flex flex-col items-end space-y-1">
          {newslettersInContent.map((el) => (
            <a
              href={el[0]}
              className="block w-min max-w-2/3 cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
            >
              {`https://web.dev.superschool.link/${userRole}/newsletter/` + el[0].split('/').pop()}
            </a>
          ))}
        </div>
      )}
      {!!noticesInContent.length && (
        <div className="mt-1 flex flex-col items-end space-y-1">
          {noticesInContent.map((el) => (
            <a
              href={el[0]}
              className="block w-min max-w-2/3 cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
            >
              {`https://web.dev.superschool.link/${userRole}/notice/` + el[0].split('/').pop()}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
