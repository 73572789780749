import { t } from 'i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrow } from 'src/assets/svg/mypage-right-arrow.svg';
import { SuperModal } from 'src/components';
import { Section, TopNavbar } from 'src/components/common';
import { UserContainer } from 'src/container/user';

export function ApplyPage() {
  const { push } = useHistory();
  const { me } = UserContainer.useContext();
  const [modalopen, setModalopen] = useState(false);

  return (
    <>
      <TopNavbar title="출결" left={<div className="h-15 w-10" />} />

      <Section>
        <div
          onClick={() => push('/student/outing')}
          className="flex cursor-pointer items-center justify-between border-b border-grey-9 pb-4"
        >
          <div>
            <div className="font-sfpro font-bold text-gray-800">확인증</div>
            <div className="text-sm text-grey-5">조퇴, 외출, 확인 전 작성 서류</div>
          </div>
          <RightArrow />
        </div>
        <div
          onClick={() => push('/student/absent')}
          className="flex cursor-pointer items-center justify-between border-b border-grey-9 pb-4"
        >
          <div>
            <div className="font-sfpro font-bold text-gray-800">
              {t(`Custom.SID${me?.schoolId}.absentTitle`, { defaultValue: '출결신고서' })}
            </div>
            <div className="text-sm text-grey-5">조퇴, 외출, 지각, 결과, 결석 후 작성 서류</div>
          </div>
          <RightArrow />
        </div>
        <div
          onClick={() => push('/student/fieldtrip')}
          className="flex cursor-pointer items-center justify-between border-b border-grey-9 pb-4"
        >
          <div>
            <div className="font-sfpro font-bold text-gray-800">체험학습</div>
            <div className="text-sm text-grey-5">체험학습, 가정학습 전후 작성하는 서류</div>
          </div>
          <RightArrow />
        </div>
      </Section>
      <SuperModal modalOpen={modalopen} setModalClose={() => setModalopen(false)}>
        <div className="mt-14 flex w-full items-center justify-center">
          <button
            children="🏔 교외 체험학습"
            className="w-4/5 rounded-lg border border-brand-1 bg-white py-5 font-bold text-brand-1"
          />
        </div>
        <div className="my-5 mb-10 flex w-full items-center justify-center">
          <button
            children="🏠 가정학습"
            className="w-4/5 rounded-lg border border-brandblue-1 bg-white py-5 font-bold text-brandblue-1"
          />
        </div>
        <div className="my-2 mb-5 flex w-full items-center justify-center">
          <button
            children="닫기"
            className="w-4/5 rounded-lg border border-gray-100 bg-gray-100 py-2 font-bold text-littleblack"
          />
        </div>
      </SuperModal>
    </>
  );
}
