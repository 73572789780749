import { ButtonHTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogout } from 'src/util/hooks';
import { IconButton } from '../IconButton';
import { Icon } from '../icons';

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
}

export function BackButton({ onClick, ...props }: BackButtonProps) {
  const history = useHistory();
  const logout = useLogout();
  const handleClick = () => {

    if (typeof onClick === 'function') {
      onClick();
    } else {
      if (history.length > 1) {
        if (history.location.pathname == '/two-factor') {
          // 2차인증에서 뒤로가기 시 로그아웃 처리
          logout();
          history.push('/');
        }
        history.goBack();
      } else {
        history.push('/');
      }
    }
  };

  return <IconButton data-cy="back" children={<Icon.Back />} onClick={handleClick} {...props} />;
}
