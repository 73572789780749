import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { range } from 'lodash';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CoachMark } from 'react-coach-mark';
import { FrontPagination } from 'src/components';
import { Label, Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { CoachPosition, Guide, useCoachMark } from 'src/components/common/CoachMark';
import { TextInput } from 'src/components/common/TextInput';
import {
  useSmsGetFieldtripsByTeacher,
  useSmsManagementGetCreditCharge,
  useSmsManagementGetCreditRemain,
  useSmsManagementGetStatistics,
} from 'src/generated/endpoint';
import { SmsCreditHistory } from 'src/generated/model';
import { isValidDate, makeDateToString } from 'src/util/time';

export function SmsPage() {
  const thisYear = new Date().getFullYear();
  const [selMonth, setSelMonth] = useState(new Date().getMonth() + 1);

  const [page, setPage] = useState(1);
  const limit = 10;

  const [startDate, setStartDate] = useState<string | undefined>(makeDateToString(new Date()));
  const [endDate, setEndDate] = useState<string | undefined>(makeDateToString(new Date()));

  const [statistics, setStatistics] = useState<SmsCreditHistory[] | undefined>();

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const { data: creditRemain } = useSmsManagementGetCreditRemain();

  useSmsManagementGetStatistics(
    {
      startDate: makeDateToString(new Date(thisYear, selMonth === 0 ? 0 : selMonth - 1, 1)),
      endDate: makeDateToString(new Date(thisYear, selMonth === 0 ? 12 : selMonth, 0)),
    },
    {
      query: {
        enabled: true,
        onSuccess: (data) => {
          data.sort((a, b) => a.calculateAt.localeCompare(b.calculateAt));

          const uniqueData = data.filter((item, index, array) => {
            return index === 0 || item.calculateAt !== array[index - 1].calculateAt;
          });

          setStatistics(uniqueData);
        },
      },
    },
  );

  const dataChart = {
    labels: statistics?.map((data) => data.calculateAt),
    datasets: [
      {
        label: '일별사용금액',
        backgroundColor: 'rgb(255, 99, 132)',
        data: statistics?.map((data) => data.usageCredit),
      },
      {
        label: '누적사용금액',
        backgroundColor: 'rgb(75, 192, 192)',
        data: statistics?.map((data) => data.accumulateUsageCredit),
      },
      {
        label: '남은금액',
        backgroundColor: 'rgb(54, 162, 235)',
        data: statistics?.map((data) => data.remainCredit),
      },
    ],
  };

  const { data: chargeHistory } = useSmsManagementGetCreditCharge({
    startDate: '2024-01-01',
    endDate: '2099-12-31',
  });

  const { data: smsHistoryList } = useSmsGetFieldtripsByTeacher(
    {
      startDate: startDate || '',
      endDate: endDate || '',
      withSuccess: true,
      withFail: false,
      page: page,
      limit: limit,
    },
    {
      query: {
        enabled: !!startDate && !!endDate,
      },
    },
  );

  const coachList: Array<Guide> = [
    {
      comment: (
        <div>
          범례를 클릭하여 해당 데이터를 보이거나 숨길 수 있습니다.
          <br /> 클릭하여 보고싶은 데이터만 남기고 숨겨보세요.
        </div>
      ),
      location: CoachPosition.TOP,
    },
    {
      comment: <div>충전을 하려면 슈퍼스쿨 고객센터로 문의하세요.</div>,
    },
  ];
  const { coach, refs } = useCoachMark('smsAdmin', coachList);

  return (
    <div className="flex">
      {<CoachMark {...coach} />}
      <Admin.Section className="w-1/2 max-w-xl">
        <Admin.H2>문자소비 이력</Admin.H2>

        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <Select value={selMonth} onChange={(e) => setSelMonth(Number(e.target.value))}>
              {/* <option key={0} value={0}>
                월별 보기
              </option> */}
              {range(1, 12 + 1, 1).map((m) => (
                <option key={m} value={m}>
                  {m}월 일별보기
                </option>
              ))}
            </Select>
          </div>

          <Label.col className="text-right">
            남은 충전금액 : {creditRemain?.remainCredit.toLocaleString() + '원'}
          </Label.col>
        </div>

        <div ref={refs[0]}>
          <Line data={dataChart} />
        </div>
        <br />
        <div className="flex gap-2" ref={refs[1]}>
          <Admin.H2>충전 이력</Admin.H2>
          <Button.sm
            children="충전하기"
            onClick={() => window.open('http://superstudy.channel.io/', '_blank')}
            className="outlined-gray"
          />
        </div>

        <Admin.Table>
          <Admin.TableHead>
            <Admin.TableRow>
              <Admin.TableHCell children="날짜" />
              <Admin.TableHCell children="금액" />
              <Admin.TableHCell children="비고" />
            </Admin.TableRow>
          </Admin.TableHead>
          <Admin.TableBody>
            {chargeHistory?.map((item, i) => (
              <Admin.TableRow key={i}>
                <Admin.TableCell children={makeDateToString(item.createdAt)} />
                <Admin.TableCell children={item.chargeCredit.toLocaleString() + '원'} />
                <Admin.TableCell children={item.chargeReason} />
              </Admin.TableRow>
            ))}
          </Admin.TableBody>
        </Admin.Table>
      </Admin.Section>

      <Admin.Section className="w-1/2 max-w-xl">
        <Admin.H2>문자전송 내역</Admin.H2>

        <div className="scroll-box h-screen-10   pb-10">
          <div className="my-3 flex items-center space-x-3">
            <TextInput
              type="date"
              value={makeDateToString(new Date(startDate || ''))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate || '')) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 text-xl font-bold">~</div>
            <TextInput
              type="date"
              value={makeDateToString(new Date(endDate || ''))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate || '')) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>

          <Admin.Table>
            <Admin.TableHead>
              <Admin.TableRow className="w-full">
                <Admin.TableHCell className="w-20" children="전송일" />
                <Admin.TableHCell children="발신자" />
                <Admin.TableHCell children="수신자" />
                <Admin.TableHCell children="내용" />
              </Admin.TableRow>
            </Admin.TableHead>
            <Admin.TableBody>
              {smsHistoryList?.items.map((item) => (
                <Admin.TableRow key={item.id}>
                  <Admin.TableCell children={format(new Date(item.createdAt), 'yyyy.MM.dd HH:mm:ss', { locale: ko })} />
                  <Admin.TableCell children={item.senderName} />
                  <Admin.TableCell children={(item.receiverName || '') + (item.useNokInfo ? '보호자' : '')} />
                  <Admin.TableCell children={item.content} />
                </Admin.TableRow>
              ))}
            </Admin.TableBody>
          </Admin.Table>

          {smsHistoryList && smsHistoryList?.total > limit && (
            <div className="grid place-items-center">
              <FrontPagination
                basePath="/admin/sms"
                total={smsHistoryList?.total}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </Admin.Section>
    </div>
  );
}
