import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Label, Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { TextInput } from 'src/components/common/TextInput';
import { teacherRoles } from 'src/constants/teacher-roles';
import { useCodeByCategoryName } from 'src/container/category';
import {
  teacherManagementCreateTeacher,
  teacherManagementUpdateTeacher,
  useTeacherManagementGetTeacherInfo,
} from 'src/generated/endpoint';
import { Category, RequestCreateTeacherDto, RequestModifyTeacherDto, Role } from 'src/generated/model';
import { form } from 'src/lib/form';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { getErrorMsg } from 'src/util/status';
import { AdminContext } from '../AdminMainPage';

export function TeacherEditPage() {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);
  const { year } = useContext(AdminContext);

  const setToastMsg = useSetRecoilState(toastState);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<RequestModifyTeacherDto & RequestCreateTeacherDto>();

  const { categoryData: teacherStates } = useCodeByCategoryName(Category.teacherstatus);
  const { data: teacherInfo } = useTeacherManagementGetTeacherInfo(id, { year }, { query: { enabled: !!id } });

  useEffect(() => teacherInfo && reset(teacherInfo.teacherData), [teacherInfo]);

  async function save(params: any) {
    if (watch('role') !== Role.HEAD && watch('role') !== Role.PRE_HEAD) {
      params.headNumber = 0;
    }
    id
      ? await teacherManagementUpdateTeacher(id, params)
          .then((result) => {
            setToastMsg(`${params.name} 님 수정완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          })
      : await teacherManagementCreateTeacher(params)
          .then((result) => {
            setToastMsg(`${params.name} 님 추가완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          });
  }

  return (
    <div>
      <div className="flex">
        <Admin.Section className="w-1/2 max-w-xl">
          <Admin.H2 className="mb-4">선생님 {id ? '수정' : '추가'}</Admin.H2>

          <Label.col>
            <Label.Text children="*이름" />
            <TextInput placeholder="이름" {...register('name', form.length(1, 100))} />
            <Label.Error children={errors.name?.message} />
          </Label.col>
          <Label.col>
            <Label.Text children="*이메일" />
            <TextInput placeholder="이메일" {...register('email', form.length(1, 100))} />
            <Label.Error children={errors.email?.message} />
          </Label.col>
          <Label.col>
            <Label.Text children="*역할" />
            <Select.lg {...register('role', { required: true })}>
              {teacherRoles.map((role) => (
                <option key={role} value={role}>
                  {t(`Role.${role}`)}
                </option>
              ))}
            </Select.lg>
          </Label.col>
          {(watch('role') === Role.HEAD || watch('role') === Role.PRE_HEAD) && (
            <Label.col>
              <Label.Text children="*담당 학년" />
              <TextInput
                placeholder="1~6"
                type="number"
                {...register(
                  'headNumber',
                  form.minmax(watch('role') === Role.HEAD || watch('role') === Role.PRE_HEAD ? 1 : 0, 6),
                )}
              />
              <Label.Error children={errors.headNumber?.message} />
            </Label.col>
          )}
          <Label.col>
            <Label.Text children="전화번호" />
            <TextInput placeholder="전화번호" {...register('phone', form.length(0, 100))} />
            <Label.Error children={errors.phone?.message} />
          </Label.col>
          <Label.col>
            <Label.Text children="부서" />
            <TextInput placeholder="부서" {...register('department', form.length(0, 100))} />
            <Label.Error children={errors.department?.message} />
          </Label.col>
          <Label.col>
            <Label.Text children="직책" />
            <TextInput placeholder="직책" {...register('position', form.length(0, 100))} />
            <Label.Error children={errors.position?.message} />
          </Label.col>
          {!!id && teacherStates && (
            <Label.col>
              <Label.Text children="상태" />
              <Select.lg
                {...register('expiredReason', {
                  onChange: (e) => {
                    const state = teacherStates.find((s) => s.name === e.target.value);
                    setValue('expired', state?.etc1 === 'true');
                    setValue('expiredReason', e.target.value);
                  },
                })}
                className="h-13"
              >
                <option value="">상태</option>
                {teacherStates?.map((state) => (
                  <option key={state.id} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </Select.lg>
            </Label.col>
          )}
        </Admin.Section>
        <Admin.Section className="w-1/2 max-w-xl">
          <div className="mt-13 flex flex-col gap-1">
            <Label.Text children="권한" />
            <Label.row className="gap-3">
              <Checkbox {...register('canEditNotice')} />
              <span>공지사항 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('canEditNewsletter')} />
              <span>가정통신문 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('canEditTimetable')} />
              <span>스케줄 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('canEditCanteen')} />
              <span>급식표 관리</span>
            </Label.row>
          </div>

          <div className="flex flex-col gap-1">
            <Label.Text children="관리자 권한" />
            <Label.row className="gap-3">
              <Checkbox {...register('adminTeacher')} />
              <span>선생님 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminStudent')} />
              <span>학생 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminParent')} />
              <span>보호자 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminClass')} />
              <span>학급 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminGroup')} />
              <span>그룹 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminApprovalLine')} />
              <span>결재라인 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminTimetable')} />
              <span>시간표 관리</span>
            </Label.row>
            <Label.row className="gap-3">
              <Checkbox {...register('adminSms')} />
              <span>문자비용 관리</span>
            </Label.row>
          </div>
        </Admin.Section>
      </div>
      <Admin.Section className="w-1/2 max-w-xl">
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Link children="취소" to={id ? `../${id}` : Routes.admin.teacher.index} className="button-lg outlined-gray" />
          <Button.lg children="저장" disabled={!isValid} onClick={handleSubmit(save)} className="filled-gray" />
        </div>
      </Admin.Section>
    </div>
  );
}
