import { Label, Select } from './common';

interface Item {
  id?: number;
  name?: string | null;
}

interface SelectMenusProps {
  label?: string;
  items?: (Item | string)[];
  allText?: string;
  value?: Item | string;
  onChange?: (item: any) => void;
  allTextVisible?: boolean;
}

export function SelectMenus({ label, items, allText, value, onChange, allTextVisible }: SelectMenusProps) {
  return (
    <Label.col>
      <Label.Text children={label} />
      <Select.lg
        value={typeof value === 'string' ? value : value?.id}
        onChange={(e) =>
          onChange?.(
            items?.filter((el) =>
              typeof el === 'string' ? el === e.target.value : el.id === Number(e.target.value),
            )[0],
          )
        }
      >
        {allText && (
          <option selected hidden={!allTextVisible} value={undefined}>
            {allText}
          </option>
        )}
        {items?.map((item, i: number) => {
          if (typeof item === 'string') {
            return (
              <option key={i} value={item || ''}>
                {item}
              </option>
            );
          } else {
            return (
              <option key={i} value={item?.id || 0}>
                {item.name}
              </option>
            );
          }
        })}
      </Select.lg>
    </Label.col>
  );
}
