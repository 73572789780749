import { useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, Chip, HorizontalScrollView, List, Select, TopNavbar } from 'src/components/common';
import { FeedsItem } from 'src/components/common/FeedsItem';
import { SearchInput } from 'src/components/common/SearchInput';
import { Tabs } from 'src/components/common/Tabs';
import { Icon } from 'src/components/common/icons';
import { NoticeCard } from 'src/components/notice/NoticeCard';
import { useCodeByCategoryName } from 'src/container/category';
import { TeacherNoticeContainer } from 'src/container/teacher-notice';
import { Category, Code, Notice, Role } from 'src/generated/model';
import { isUpdateNoticeState, meState } from 'src/store';
import { TabType } from 'src/types';
import { DateFormat, DateUtil } from 'src/util/date';
import { BoardMobilePage } from '../board/BoardMobilePage';
import { NewsletterMobilePage } from '../newsletter/NewsletterMobilePage';
import { NoticeAddPage } from './NoticeAddPage';
import { NoticeDetailPage } from './NoticeDetailPage';
import { NoticeMobilePage } from './NoticeMobilePage';

const filters = ['제목', '작성자'];

function NoticePageView() {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [tabType, setTabType] = useState(TabType.NOTICE);
  const setIsUpdateNotice = useSetRecoilState(isUpdateNoticeState);

  const meRecoil = useRecoilValue(meState);

  const { filteredNoticeList, category, isNoticeListLoading, isNoticeListError, unReadNoticeList, setCategory } =
    TeacherNoticeContainer.useContext();

  const [filter, setFilter] = useState(filters[0]);
  const [searchWriter, setSearchWriter] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const handleFilterChange = (e: any) => {
    setSearchWriter('');
    setSearchTitle('');
    setFilter(e.target.value);
  };

  useEffect(() => {
    const getLocal = localStorage.getItem('tabType');
    if (!getLocal) {
      return;
    } else if (getLocal === 'BOARD') {
      setTabType(TabType.BOARD);
    } else if (getLocal === 'NOTICE') {
      setTabType(TabType.NOTICE);
    } else if (getLocal === 'NEWSLETTER') {
      setTabType(TabType.NEWSLETTER);
    }
  }, [tabType]);

  const isDetail = !pathname.endsWith('/teacher/notice');
  const { categoryData } = useCodeByCategoryName(Category.noticetype);

  return (
    <>
      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <TopNavbar title="공지" left={<BackButton onClick={() => push('/')} />} />
        <Tabs>
          {[
            { name: '공지사항', type: TabType.NOTICE },
            { name: '학급 게시판', type: TabType.BOARD },
            { name: '가정통신문', type: TabType.NEWSLETTER },
          ].map((tab) => (
            <Tabs.Button
              key={tab.name}
              children={tab.name}
              selected={tabType === tab.type}
              onClick={() => {
                setTabType(tab.type);
                localStorage.setItem('tabType', tab.type);
              }}
            />
          ))}
        </Tabs>

        {tabType === TabType.NOTICE && <NoticeMobilePage />}
        {tabType === TabType.NEWSLETTER && <NewsletterMobilePage />}
        {tabType === TabType.BOARD && <BoardMobilePage />}
      </div>

      {/* Desktop V */}
      {isNoticeListLoading && <Blank reversed />}
      {isNoticeListError && <ErrorBlank />}
      <div className="col-span-2 hidden h-screen md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">학교 공지사항</h1>
          {(meRecoil?.canEditNotice ||
            meRecoil?.role === Role.ADMIN ||
            meRecoil?.role === Role.HEAD ||
            meRecoil?.role === Role.PRE_HEAD ||
            meRecoil?.role === Role.PRE_PRINCIPAL ||
            meRecoil?.role === Role.PRINCIPAL ||
            meRecoil?.role === Role.VICE_PRINCIPAL ||
            meRecoil?.role === Role.HEAD_PRINCIPAL) && (
            <Link
              children="추가하기"
              to="/teacher/notice/add"
              className="rounded-md bg-light_orange px-4 py-2 text-sm text-brand-1 hover:bg-brand-1 hover:text-light_orange focus:outline-none"
            />
          )}
        </div>
        <div className="space-y-0h-0.5 w-full bg-gray-100"></div>
        <HorizontalScrollView classNameInner="my-4">
          <Chip
            children="전체"
            selected={category === '전체'}
            onClick={() => setCategory('전체')}
            className="min-w-max py-1.5"
          />
          {categoryData?.map((el: Code) => (
            <Chip
              key={el.name}
              children={el.name}
              selected={el.name === category}
              onClick={() => setCategory(el.name)}
              className="min-w-max py-1.5"
            />
          ))}
        </HorizontalScrollView>
        <div className="flex items-center space-x-2 px-6 pb-6 pt-3">
          <div className="cursor-pointer">
            <Select.lg value={filter} onChange={handleFilterChange}>
              {filters.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Select.lg>
          </div>
          <div className="flex w-full items-center space-x-2">
            {filter === '제목' ? (
              <SearchInput
                placeholder="제목을 입력해주세요."
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                className="w-full"
              />
            ) : (
              <SearchInput
                placeholder="작성자를 입력해주세요."
                value={searchWriter}
                onChange={(e) => setSearchWriter(e.target.value)}
                className="w-full"
              />
            )}
            <Icon.Search />
          </div>
        </div>
        <div className="h-screen-16 overflow-y-auto ">
          {filteredNoticeList
            ?.filter(
              (notice: Notice) =>
                (searchWriter === '' || notice?.user?.name.includes(searchWriter)) &&
                (searchTitle === '' || notice?.title.includes(searchTitle)),
            )
            .map((notice: Notice) => (
              <NoticeCard key={notice.id} notice={notice} onClick={() => setIsUpdateNotice(false)} />
            ))}
        </div>
      </div>

      <div
        className={`scroll-box col-span-4 h-screen overflow-y-scroll bg-gray-50 p-0 md:p-6 ${
          isDetail ? 'block' : 'hidden'
        }`}
      >
        <Switch>
          <Route path="/teacher/notice/add" component={() => <NoticeAddPage categoryData={categoryData} />} />
          <Route path="/teacher/notice/:id" component={() => <NoticeDetailPage categoryData={categoryData} />} />
        </Switch>
      </div>
    </>
  );
}

export function NoticePage() {
  return (
    <TeacherNoticeContainer.ContextProvider>
      <NoticePageView />
    </TeacherNoticeContainer.ContextProvider>
  );
}
