import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Blank, Select as CommonSelect, List } from 'src/components/common';
import { useStudentSemetsersScore } from 'src/container/student-semesters-score';

interface SemesterScoreCardProps {
  studentId: string;
}

// 데이터를 불러오는 로직을 수정하고 모든 학기에 관한 성적을 전달해서 SemesterScoreCard 컴포넌트 내부에서 필터링 처리하도록
export default function SemesterScoreCard({ studentId }: SemesterScoreCardProps) {
  const { scores, isLoading } = useStudentSemetsersScore(Number(studentId));

  useEffect(() => {
    if (scores) {
      const newScoreDatas: any = {};

      scores.forEach((scoreData: any) => {
        const label = `${scoreData.grade}학년 ${scoreData.semester}학기`;
        newScoreDatas[label] = scoreData.scores;
      });
      setScoreDatas(newScoreDatas);
    }
  }, [scores]);
  const [scoreDatas, setScoreDatas] = useState<any>({});
  const [selectedSemester, setSelectedSemester] = useState<string>('성적분석');

  // 학생 데이터를 기반으로 성적이 추가된 학기만 보이도록 처리
  const semesters = _.chain(scores)
    .map((score) => `${score.grade}학년 ${score.semester}학기`)
    .uniq()
    .value();

  // 교과별 성적 데이터 추출
  const groupedData = _.chain(scores)
    .map('scores')
    .flatten()
    .groupBy('subject_group')
    .mapValues((subjectScores) => _.groupBy(subjectScores, (score) => `${score.grade}학년 ${score.semester}학기`))
    .value();

  const semesterOptions = ['성적분석', ...semesters];

  if (isLoading) return <Blank />;
  return (
    <>
      <div className="flex w-full flex-col items-center space-x-2 md:flex-row">
        <CommonSelect placeholder="성적을 선택해주세요" defaultValue={'종합성적'}>
          <option>종합성적</option>
        </CommonSelect>
        <List className="flex flex-row gap-2 md:m-0">
          {semesterOptions.map((sem) => (
            <span
              key={sem}
              onClick={() => setSelectedSemester(sem)}
              className={`${
                selectedSemester === sem ? 'font-bold' : 'text-gray-300'
              } cursor-pointer transition-all hover:font-bold hover:text-black`}
            >
              {sem}
            </span>
          ))}
        </List>
      </div>
      {selectedSemester === '성적분석' ? (
        <div className="relative mt-4 overflow-x-auto rounded-lg shadow-md">
          <table className="w-full text-left text-gray-500 rtl:text-right">
            <thead className="bg-gray-200 uppercase text-gray-700">
              <tr>
                <th scope="col" className="h-4 border-r border-gray-300 p-4 text-center">
                  교과
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  학년/학기
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  단위
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  원 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  Z 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  석차(동점자 수)
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  성취도
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급 분포
                </th>
                <th scope="col" className="px-2 py-2 text-center">
                  목표 등급
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData).map((subjectGroup) => (
                <React.Fragment key={subjectGroup}>
                  {Object.keys(groupedData[subjectGroup]).map((semester, semesterIndex) => (
                    <React.Fragment key={semester}>
                      {groupedData[subjectGroup][semester].map((score, index) => (
                        <tr key={`${score.subject_name}-${score.semester}`} className="border-b bg-white">
                          {index === 0 && semesterIndex === 0 && (
                            <td
                              className="border-b border-r border-gray-300 px-2 py-2 text-center text-lg font-bold"
                              rowSpan={Object.values(groupedData[subjectGroup]).flat().length}
                            >
                              {subjectGroup}
                            </td>
                          )}
                          <td className="border-r border-gray-300 px-2 py-2">{score.subject_name}</td>
                          <td className="border-r border-gray-300 px-2 py-2">{`${score.grade}학년 ${score.semester}학기`}</td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">{score.credit}</td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">{score.score}</td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">
                            {Number(score.zscore).toFixed(2)}
                          </td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">
                            {score.num_same_score || '-'}
                          </td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">{score.rank_score || '-'}</td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">
                            {score.rank_alphabet || '-'}
                          </td>
                          <td className="border-r border-gray-300 px-2 py-2 text-center">{score.percentage || '-'}</td>
                          <td className="px-2 py-2 text-center">{score.target_rank || '-'}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="relative mt-4 overflow-x-auto rounded-lg shadow-md">
          <table className="w-full text-left text-gray-500 rtl:text-right">
            <thead className="bg-gray-200 uppercase text-gray-700">
              <tr>
                <th scope="col" className="h-4 border-r border-gray-300 p-4 text-center">
                  교과
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목 종류
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  단위
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  원 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  Z 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  석차(동점자 수)
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  성취도
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급 분포
                </th>
                <th scope="col" className="px-2 py-2 text-center">
                  목표 등급
                </th>
              </tr>
            </thead>
            <tbody>
              {scoreDatas[selectedSemester]?.map((score: any) => (
                <tr className="border-b bg-white hover:bg-gray-50" key={score.zscore}>
                  <th
                    scope="row"
                    className="h-4 whitespace-nowrap border-r border-gray-300 p-4 font-medium text-gray-900"
                  >
                    {score.subject_group}
                  </th>
                  <td className="border-r border-gray-300 px-2 py-2">{score.subject_name}</td>
                  <td className="border-r border-gray-300 px-2 py-2">{score.subject_type}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.credit}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.score}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{Number(score.zscore).toFixed(2)}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-end">{score.num_same_score || '-'}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.rank_score || '-'}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.rank_alphabet || '-'}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{score.percentage || '-'}</td>
                  <td className="px-2 py-2 text-center">{score.target_rank || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
