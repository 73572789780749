import { ReactChild, ReactFragment, ReactPortal, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Viewer from 'react-viewer';
import { useRecoilValue } from 'recoil';
import { ErrorBlank, SuperModal } from 'src/components';
import { BackButton, Blank, Label, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { FeedsDetail } from 'src/components/common/FeedsDetail';
import { PdfViewer } from 'src/components/common/PdfViewer';
import { useTeacherNewsletterDetail } from 'src/container/teacher-newsletter-detail';
import { NewsletterType, Role } from 'src/generated/model';
import { Routes } from 'src/routes';
import { meState } from 'src/store';
import { UserDatas } from 'src/types';
import { DateFormat, DateUtil } from 'src/util/date';

export function NewsletterDetailPage() {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const me = useRecoilValue(meState);

  const {
    newsletter,
    isLoading,
    images,
    Pdfs,
    documents,
    viewerImages,
    isPublishModalOpen,
    errorMessage,
    setIsPublishModalOpen,
    handleNewsletterDelete,
    handleNewsletterPublish,
  } = useTeacherNewsletterDetail({ id: +id });

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [hasPdfModalOpen, setPdfModalOpen] = useState(false);
  const [focusPdfFile, setFocusPdfFile] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  // 설문조사용도
  const [content, setContent] = useState<Record<string, string>>({});

  const getFeedsDetail = () => {
    return (
      <FeedsDetail
        category1={newsletter?.category || '가정통신문'}
        category1Color="light_golden"
        category2={newsletter?.type === NewsletterType.NOTICE ? '공지' : '설문'}
        category2Color="lavender_blue"
        sendTo={
          (newsletter?.toStudent ? '학생' : '') +
          (newsletter?.toStudent && newsletter?.toParent ? '/' : '') +
          (newsletter?.toParent ? '보호자' : '')
        }
        sendToColor="gray-100"
        useSubmit={newsletter?.type !== NewsletterType.NOTICE}
        submitDate={DateUtil.formatDate(newsletter?.endAt || '', DateFormat['YYYY.MM.DD HH:mm'])}
        submitYN={false}
        title={newsletter?.title}
        contentText={newsletter?.content}
        contentImages={newsletter?.images}
        contentFiles={newsletter?.files}
        contentSurvey={newsletter?.surveyContent}
        surveyResult={content}
        //writer={newsletter?.writer.name}
        createAt={DateUtil.formatDate(newsletter?.createdAt || '', DateFormat['YYYY.MM.DD HH:mm'])}
      />
    );
  };

  return (
    <>
      {/* Mobile V */}
      <div className="block bg-white md:hidden">
        {isLoading && <Blank />}
        {errorMessage && <ErrorBlank text={errorMessage} />}
        <TopNavbar title="가정통신문" left={<BackButton />} />

        <div className="flex">
          {newsletter?.klasses
            ?.sort((a, b) => +a - +b)
            .map((klass) => (
              <span
                key={klass}
                className="ml-2 mt-2 rounded-full border border-gray-400 px-3 py-2 text-sm font-semibold text-gray-500"
              >
                {klass}학년
              </span>
            ))}
        </div>
        {getFeedsDetail()}
        <br />
        <br />
        <br />
      </div>

      {/* Desktop V */}
      <div className="hidden md:block">
        <div className="rounded-lg border bg-white p-5">
          {isLoading && <Blank reversed />}
          {errorMessage && <ErrorBlank text={errorMessage} />}
          {isLoading && <Blank reversed />}
          <Section>
            <div className="flex  w-full justify-between space-x-2">
              <div className="flex">
                {!newsletter?.toPerson &&
                  newsletter?.klasses
                    ?.sort((a, b) => +a - +b)
                    .map((klass) => (
                      <span
                        key={klass}
                        className="mb-2 mr-2 rounded-full border border-gray-400 px-3 py-2 text-sm font-semibold text-gray-500"
                      >
                        {klass}학년
                      </span>
                    ))}
              </div>
              <div className="font-base flex cursor-pointer space-x-4 text-gray-500">
                {!newsletter?.isPublished && (
                  <div className="text-gray-700" onClick={() => push(`${Routes.teacher.newsletter}/${id}/edit`)}>
                    수정
                  </div>
                )}
                {newsletter?.isPublished && (
                  <div
                    className="px-4 text-blue-700"
                    onClick={() => push(`${Routes.teacher.newsletter}/${id}/edit/reuse`)}
                  >
                    재사용
                  </div>
                )}
                {(me?.role === Role.ADMIN || newsletter?.writerId === me?.id) && (
                  <div className="cursor-pointer text-red-400" onClick={() => setDeleteModalOpen(true)}>
                    삭제
                  </div>
                )}
              </div>
            </div>

            <div className="flex w-full justify-center">
              <div className=" w-[500px] rounded-lg border p-3">{getFeedsDetail()}</div>
            </div>
            <br />
            {newsletter?.toPerson && (
              <>
                <Label.col>
                  <Label.Text>* 수신자 확인</Label.Text>
                  <div className=" overflow-y-auto">
                    <table className="w-full border-collapse border border-gray-300">
                      <thead>
                        <tr>
                          <th className="w-14 border border-gray-300">번호</th>
                          <th className="w-14 min-w-max border-gray-300">이름</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newsletter?.userInfo.map((row: UserDatas | any, rowIndex: any) => (
                          <tr>
                            <td className="border border-gray-300 text-center">
                              {row.sn === undefined
                                ? row.children[0].studentNumber + ' ' + row.children[0].name + '보호자'
                                : ''}
                              {row.sn}
                            </td>
                            <td className="border border-gray-300 text-center">{row.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Label.col>
              </>
            )}

            {newsletter?.isPublished === false && (
              <Button.xl
                children="발행하기"
                onClick={() => setIsPublishModalOpen(true)}
                className="filled-primary w-full"
              />
            )}

            <SuperModal modalOpen={isPublishModalOpen} setModalClose={() => setIsPublishModalOpen(false)} width="w-100">
              <div className="px-12 py-6">
                <div className="mb-4 w-full text-center text-lg font-bold text-gray-900">
                  해당 가정통신문을 발행하시겠습니까?
                </div>
                <div className="mb-6 w-full text-center text-sm">
                  가정통신문을 발행하면 학생 또는 보호자가 열람하고 답변할 수 있으며 <br />
                  수정이 불가능해집니다.
                </div>
                <Button.xl children="발행하기" onClick={handleNewsletterPublish} className="filled-primary w-full" />
              </div>
            </SuperModal>
            <SuperModal modalOpen={isDeleteModalOpen} setModalClose={() => setDeleteModalOpen(false)} width="w-max">
              <Section className="mt-7">
                <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
                  정말 해당 가정통신문을 삭제하시겠습니까?
                </div>
                <Button.xl children="삭제하기" onClick={() => handleNewsletterDelete(+id)} className="filled-primary" />
              </Section>
            </SuperModal>
          </Section>
        </div>
      </div>
      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable
          noImgDetails
          scalable={false}
          images={viewerImages}
          onClose={() => setImagesModalOpen(false)}
          activeIndex={activeIndex}
        />
      </div>
      <div className="absolute">
        <PdfViewer isOpen={hasPdfModalOpen} fileUrl={focusPdfFile} onClose={() => setPdfModalOpen(false)} />
      </div>
    </>
  );
}
