import { useHistory } from 'react-router-dom';
import { BackButton, BottomFixed, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Routes } from 'src/routes';

interface FindIdSuccessPageProps {
  phone: string;
}

export function FindIdSuccessPage({ phone }: FindIdSuccessPageProps) {
  const { push } = useHistory();

  return (
    <>
      <TopNavbar
        title="아이디 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <Section>
        <h1 className="text-2xl font-bold">아이디 전송 성공</h1>

        <div className="w-full text-xl">
          &nbsp;등록된 휴대전화로({phone}) 아이디를 보냈습니다.
          <br />
          &nbsp;카카오톡을 확인해주세요.
        </div>
        <div className="w-full text-red-500">* 카카오톡으로 아이디를 받지 못 한 경우 슈퍼스쿨에 문의해주세요.</div>
      </Section>
      <BottomFixed>
        <Section>
          <Button.lg children="로그인하기" onClick={() => push(Routes.auth.login)} className="filled-primary" />
        </Section>
      </BottomFixed>
    </>
  );
}
