export const checkFileSizeLimit20MBex = (files: FileList | null) => {
  if (files) {
    let totalSize = 0;
    for (const file of files) {
      totalSize += file.size;
    }
    return totalSize <= 20 * 1024 * 1024;
  }

  return true;
};

export const checkFileSizeLimit20MB = (files: (File | null | undefined)[]) => {
  const totalSize = files.reduce((acc, file) => {
    if (file === null || file === undefined) {
      return acc;
    }
    if (file instanceof File) {
      return acc + file.size;
    }
    return acc;
  }, 0);
  return totalSize <= 20 * 1024 * 1024;
};

export const getFileNameFromUrl = (url: string) => {
  return url.lastIndexOf('/') >= 0 ? url.slice(url.lastIndexOf('/') + 15) : url;
};

export const getFileNameFromUrlToAnn = (url: string) => {
  return url.lastIndexOf('/') >= 0 ? url.slice(url.lastIndexOf('/') + 1) : url;
};

export const isPdfFile = (fileName: String) => {
  let _lastDot = fileName.lastIndexOf('.');

  // 확장자 명만 추출한 후 소문자로 변경
  let _fileExt = fileName.substring(_lastDot, fileName.length).toLowerCase();
  if (_fileExt === '.pdf') {
    return true;
  } else {
    return false;
  }
};

export const isImageFile = (fileName: string) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
  const _lastDot = fileName.lastIndexOf('.');

  if (_lastDot === -1) return false; // 파일명에 확장자가 없는 경우

  // 확장자 명만 추출한 후 소문자로 변경
  const _fileExt = fileName.substring(_lastDot).toLowerCase();
  return imageExtensions.includes(_fileExt);
};

export const getExtOfFilename = (fileName: String) => {
  let _lastDot = fileName.lastIndexOf('.');

  // 확장자 명만 추출한 후 소문자로 변경
  return fileName.substring(_lastDot, fileName.length).toLowerCase();
};
