import { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Blank, Chip, HorizontalScrollView, Select } from 'src/components/common';
import { SearchInput } from 'src/components/common/SearchInput';
import { Icon } from 'src/components/common/icons';
import { NewsletterCard } from 'src/components/newsletter/NewsletterCard';
import { useTeacherNewsletter } from 'src/container/teacher-newsletter';
import { Newsletter, NewsletterCategoryEnum, Role } from 'src/generated/model';
import { meState, newsletterOpenedGroupState } from 'src/store';
import { NewsletterAddPage } from './NewsletterAddPage';
import { NewsletterCheckDownloadPage } from './NewsletterCheckDownloadPage';
import { NewsletterCheckPage } from './NewsletterCheckPage';
import { NewsletterDetailPage } from './NewsletterDetailPage';
import { NewsletterDownloadPage } from './NewsletterDownloadPage';
import { NewsletterSubmitPage } from './NewsletterSubmitPage';

const filters = ['제목', '작성자'];

export function NewsletterPage() {
  const me = useRecoilValue(meState);
  const setNewsletterOpenedGroup = useSetRecoilState(newsletterOpenedGroupState);
  const { newsletters, category, isLoading, setCategory } = useTeacherNewsletter();

  const [filter, setFilter] = useState(filters[0]);
  const [searchWriter, setSearchWriter] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const handleFilterChange = (e: any) => {
    setSearchWriter('');
    setSearchTitle('');
    setFilter(e.target.value);
  };

  return (
    <>
      {/* Desktop V */}
      {isLoading && <Blank reversed />}
      {/*{error && <ErrorBlank />}*/}
      <div className="col-span-2 hidden h-screen md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">가정통신문</h1>
          {(me?.canEditNewsletter ||
            me?.role === Role.ADMIN ||
            me?.role === Role.HEAD ||
            me?.role === Role.PRE_HEAD ||
            me?.role === Role.PRE_PRINCIPAL ||
            me?.role === Role.PRINCIPAL ||
            me?.role === Role.VICE_PRINCIPAL ||
            me?.role === Role.HEAD_PRINCIPAL) && (
            <Link
              children="추가하기"
              to="/teacher/newsletter/add"
              className="rounded-md bg-light_orange px-4 py-2 text-sm text-brand-1 hover:bg-brand-1 hover:text-light_orange focus:outline-none"
            />
          )}
        </div>
        <div className="scroll-box h-0.5 bg-gray-100"></div>
        <HorizontalScrollView classNameInner="my-4">
          <Chip
            children="전체"
            selected={!category}
            onClick={() => setCategory(undefined)}
            className="min-w-max py-1.5"
          />
          {Object.keys(NewsletterCategoryEnum).map((newsletterCategory) => (
            <Chip
              key={newsletterCategory}
              children={newsletterCategory}
              selected={newsletterCategory === category}
              onClick={() => setCategory(newsletterCategory as NewsletterCategoryEnum)}
              className="min-w-max py-1.5"
            />
          ))}
        </HorizontalScrollView>
        <div className="flex items-center space-x-2 px-6 pb-6 pt-3">
          <div className="cursor-pointer">
            <Select.lg value={filter} onChange={handleFilterChange}>
              {filters.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Select.lg>
          </div>
          <div className="flex w-full items-center space-x-2">
            {filter === '제목' ? (
              <SearchInput
                placeholder="제목을 입력해주세요."
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                className="w-full"
              />
            ) : (
              <SearchInput
                placeholder="작성자를 입력해주세요."
                value={searchWriter}
                onChange={(e) => setSearchWriter(e.target.value)}
                className="w-full"
              />
            )}
            <Icon.Search />
          </div>
        </div>
        <div className="h-screen-16 overflow-y-auto">
          {newsletters
            ?.filter(
              (newsletter: Newsletter) =>
                (searchWriter === '' ||
                  (newsletter &&
                    newsletter.writer &&
                    newsletter.writer.name &&
                    newsletter.writer.name.includes(searchWriter))) &&
                (searchTitle === '' || (newsletter && newsletter.title && newsletter.title.includes(searchTitle))),
            )
            .map((newsletter: Newsletter) => (
              <NewsletterCard
                key={newsletter.id}
                newsletter={newsletter}
                onClick={() => setNewsletterOpenedGroup([])}
              />
            ))}
        </div>
      </div>

      {/* Desktop V */}
      <div className="bg-gray-50 md:col-span-4 md:overflow-y-auto md:p-6 ">
        <Switch>
          <Route path="/teacher/newsletter/add" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/:id/edit" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/submit/:id" component={NewsletterSubmitPage} />
          <Route path="/teacher/newsletter/check/:id" component={NewsletterCheckPage} />
          <Route path="/teacher/newsletter/download/:id" component={NewsletterDownloadPage} />
          <Route path="/teacher/newsletter/unread-student-download/:id" component={NewsletterCheckDownloadPage} />
          <Route path="/teacher/newsletter/:id" component={() => <NewsletterDetailPage />} />
        </Switch>
      </div>
    </>
  );
}
