import { useEffect, useRef, useState } from 'react';
import { attendees } from 'src/pages/teacher/calendar/CalendarPage';
import { makeDateToString } from 'src/util/time';

interface CustomTuiModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (e: any) => void;
  submitText: string;
  calendars: any[];
  schedule: any;
  startDate: Date;
  endDate: Date;
}

export function CustomTuiModal({
  isOpen = false,
  onClose,
  onSubmit,
  calendars = [],
  schedule,
  startDate,
  endDate,
}: CustomTuiModalProps) {
  const [openSelectCalendars, setOpenSelectCalendars] = useState(false);
  const [openSelectAttendees, setOpenSelectAttendees] = useState(false);
  const wrapperSelectCalendarsRef = useRef<any>(null);
  const wrapperSelectAttendeesRef = useRef<any>(null);
  const subjectRef = useRef<any>(null);

  const [calendarId, setCalendarId] = useState(calendars[0].id);
  const [attendee, setAttendee] = useState(attendees[0].name);
  const [title, setTitle] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');

  const handleClick = (e: any) => {
    if (wrapperSelectCalendarsRef.current?.contains(e.target)) {
      return;
    }
    if (wrapperSelectAttendeesRef.current?.contains(e.target)) {
      return;
    }
    setOpenSelectCalendars(false);
    setOpenSelectAttendees(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  useEffect(() => {
    if (schedule) {
      setCalendarId(schedule.calendarId);
      setAttendee(schedule?.attendee);
      setTitle(schedule.title);
      setStartAt(makeDateToString(startDate));
      setEndAt(makeDateToString(endDate));
    }
    return () => {};
  }, [schedule, startDate, endDate]);

  useEffect(() => {
    if (startAt && endAt) {
      if (startAt > endAt) setEndAt(startAt);
    }
  }, [startAt, endAt]);

  useEffect(() => {
    if (startDate && endDate) {
      setStartAt(makeDateToString(startDate));
      setEndAt(makeDateToString(endDate));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setTitle('');
    setCalendarId('0');
    setAttendee(attendees[0].name);
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-littleblack ${
        !isOpen && 'hidden'
      }`}
    >
      <div className="tui-full-calendar-popup-container">
        <div className="flex flex-row-reverse">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex">
          <div
            ref={wrapperSelectCalendarsRef}
            className={`tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-section-calendar ${
              openSelectCalendars && 'tui-full-calendar-open'
            }`}
          >
            <button
              onClick={() => setOpenSelectCalendars(!openSelectCalendars)}
              className="tui-full-calendar-button tui-full-calendar-dropdown-button tui-full-calendar-popup-section-item flex items-center justify-between text-left"
            >
              <span
                className="tui-full-calendar-icon tui-full-calendar-calendar-dot"
                style={{ backgroundColor: calendars?.find((element: any) => element.id === calendarId)?.bgColor }}
              />
              <span id="tui-full-calendar-schedule-calendar" className="tui-full-calendar-content truncate">
                {calendars?.find((element) => element.id === calendarId)?.name}
              </span>
              <span className="tui-full-calendar-icon tui-full-calendar-dropdown-arrow" />
            </button>
            <ul className="tui-full-calendar-dropdown-menu" style={{ zIndex: 1004 }}>
              {calendars.map((element, i) => (
                <li
                  onClick={() => {
                    setCalendarId(element.id);
                    setOpenSelectCalendars(false);
                  }}
                  key={i}
                  className="tui-full-calendar-popup-section-item tui-full-calendar-dropdown-menu-item"
                  data-calendar-id={element.id}
                >
                  <span
                    className="tui-full-calendar-icon tui-full-calendar-calendar-dot"
                    style={{ backgroundColor: element.bgColor }}
                  />
                  <span className="tui-full-calendar-content">{element?.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div
            ref={wrapperSelectAttendeesRef}
            className={`tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-section-state ml-3 w-60 ${
              openSelectAttendees && 'tui-full-calendar-open'
            }`}
          >
            <button
              onClick={() => setOpenSelectAttendees(!openSelectAttendees)}
              className="tui-full-calendar-button tui-full-calendar-dropdown-button tui-full-calendar-popup-section-item flex w-60 items-center justify-between text-left"
            >
              <span className="tui-full-calendar-icon tui-full-calendar-ic-state" />
              <span
                id="tui-full-calendar-schedule-state"
                className="tui-full-calendar-content w-100"
                style={{ width: '200px' }}
              >
                {attendees?.find((element) => element.name === attendee)?.name}
              </span>
              <span className="tui-full-calendar-icon tui-full-calendar-dropdown-arrow" />
            </button>
            <ul className="tui-full-calendar-dropdown-menu" style={{ zIndex: 1004 }}>
              {attendees.map((element, i) => (
                <li
                  onClick={() => {
                    setAttendee(element.name);
                    setOpenSelectAttendees(false);
                  }}
                  key={i}
                  className="tui-full-calendar-popup-section-item tui-full-calendar-dropdown-menu-item"
                >
                  <span className="tui-full-calendar-icon tui-full-calendar-none" />
                  <span className="tui-full-calendar-content" style={{ width: '200px' }}>
                    {element?.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="tui-full-calendar-popup-section">
          <div className="tui-full-calendar-popup-section-item tui-full-calendar-section-location">
            <span className="tui-full-calendar-icon tui-full-calendar-ic-title" />
            <input
              ref={subjectRef}
              id="tui-full-calendar-schedule-title"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="tui-full-calendar-content"
            />
          </div>
        </div>
        <div>
          <input
            type="date"
            value={startAt}
            className="mb-5 h-10 w-60 rounded-md
              border border-gray-200 px-4 placeholder-gray-400
                focus:border-brand-1 focus:ring-0 disabled:bg-gray-100
                disabled:text-gray-400 sm:text-sm"
            onChange={(e) => setStartAt(e.target.value)}
          />
          <span className="tui-full-calendar-section-date-dash">-</span>
          <input
            type="date"
            value={endAt}
            className="mb-5 h-10 w-60 rounded-md
              border border-gray-200 px-3 placeholder-gray-400
                focus:border-brand-1 focus:ring-0 disabled:bg-gray-100
                disabled:text-gray-400 sm:text-sm"
            onChange={(e) => setEndAt(e.target.value)}
          />
        </div>
        <div className="tui-full-calendar-section-button-save">
          <button
            children="저장"
            onClick={() => {
              if (!title) {
                subjectRef.current.focus();
              } else {
                const event: any = {
                  calendarId,
                  attendee,
                  title,
                  start: startAt,
                  end: endAt,
                  isAllDay: makeDateToString(new Date(startAt)) === makeDateToString(new Date(endAt)),
                  location: '',
                };
                const _start = new Date(startAt);
                _start.setHours(0, 0, 0, 0);
                event.start = _start;

                const _end = new Date(endAt);
                _end.setHours(23, 59, 59, 999);
                event.end = _end;

                onSubmit(event);
              }
            }}
            className="tui-full-calendar-button tui-full-calendar-confirm tui-full-calendar-popup-save"
          />
        </div>
      </div>
    </div>
  );
}
