import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { ReactComponent as FileUploadImg } from 'src/assets/svg/upload-image.svg';

export interface ImageUploadProps extends InputHTMLAttributes<HTMLInputElement> {}

export function ImageUpload({ children, className, ...props }: ImageUploadProps) {
  return (
    <label className={clsx('image-upload', className)}>
      {children || (
        <>
          <FileUploadImg />
          <p className="text-sm text-brand-1">이미지,PDF파일을 선택해주세요.</p>
        </>
      )}
      <input type="file" accept=".png, .jpeg, .jpg" className="hidden" {...props} />
    </label>
  );
}
