import { Link, useLocation } from 'react-router-dom';
import { Badge } from 'src/components/common';
import { Fieldtrip, FieldtripStatus } from 'src/generated/model';
import { makeStartEndToString } from 'src/util/time';

interface FieldtripResultCardProps {
  fieldtrip: Fieldtrip;
}

export function FieldtripResultCard({ fieldtrip }: FieldtripResultCardProps) {
  const { pathname, search } = useLocation();

  let text = <div className="text-sm text-red-500"> 작성 전</div>;

  switch (fieldtrip?.fieldtripResultStatus) {
    case FieldtripStatus.BEFORE_PARENT_CONFIRM:
      text = <div className="text-sm text-red-500">학부모 승인 전</div>;
      break;
    case FieldtripStatus.PROCESSING:
      text = <div className="text-sm text-red-500">{fieldtrip?.nextResultApproverTitle} 승인 전</div>;
      break;
    case FieldtripStatus.PROCESSED:
      text = <div className="text-sm text-gray-600">승인 완료</div>;
      break;
    case FieldtripStatus.RETURNED:
      text = <div className="text-sm text-brand-1">반려됨</div>;
      break;
    case FieldtripStatus.DELETE_APPEAL:
      text = <div className="text-sm text-red-800">삭제 요청</div>;
      break;
  }
  return (
    <>
      <Link to={`/teacher/fieldtrip/result/${fieldtrip.id}${search}`}>
        <div
          className={
            pathname.startsWith(`/teacher/fieldtrip/result/${fieldtrip.id}`)
              ? 'relative flex w-full items-center justify-between bg-gray-50 p-4'
              : 'relative flex w-full items-center justify-between p-4'
          }
        >
          <div>
            <h3 className="text-lg font-semibold">
              [결과보고서] {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
            </h3>
            <div className="my-1 flex space-x-2 text-sm font-semibold">
              {fieldtrip?.type === 'HOME' ? (
                <Badge children="가정" className="bg-brand-5 text-brand-1" />
              ) : (
                <Badge children="교외" className="bg-brand-5 text-brand-1" />
              )}

              <div>{fieldtrip.type === 'SUBURBS' ? fieldtrip?.form || '교외체험학습' : '가정학습'}</div>
            </div>
            <div className="pt-1 text-xs text-gray-500">
              기간 :{' '}
              {fieldtrip?.startAt && fieldtrip?.endAt && makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
            </div>
            <div className="pt-1 text-xs text-gray-500">신청일 : {fieldtrip.resultReportedAt}</div>
          </div>
          <div>{text}</div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
}
