import { t } from 'i18next';
import { range } from 'lodash';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Route, Switch, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Refresh } from 'src/assets/svg/refresh.svg';
import { ErrorBlank, FrontPagination } from 'src/components';
import { AbsentCard } from 'src/components/absent/AbsentCard';
import { AbsentsExcelDownloadView } from 'src/components/absent/AbsentsExcelDownloadView';
import { BackButton, Blank, Select, TopNavbar } from 'src/components/common';
import { SearchInput } from 'src/components/common/SearchInput';
import { Icon } from 'src/components/common/icons';
import { FieldtripCard } from 'src/components/fieldtrip/FieldtripCard';
import { FieldtripExcelDownloadView } from 'src/components/fieldtrip/FieldtripExcelDownloadView';
import { OutingCard } from 'src/components/outing/OutingCard';
import { OutingsExcelDownloadView } from 'src/components/outing/OutingExcelDownloadView';
import { AbsentsDownloadView } from 'src/components/pdfDocs/AbsentsDownloadView';
import { FieldtripsDownloadView } from 'src/components/pdfDocs/FieldtripsDownloadView';
import { useTeacherHistory } from 'src/container/teacher-history';
import { useTeacherKlassGroup } from 'src/container/teacher-klass-groups';
import { UserContainer } from 'src/container/user';
import { ResponseCreateOutingDto } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { HistoryAbsentDetailPage } from './HistoryAbsentDetailPage';
import { HistoryFieldtripDetailPage } from './HistoryFieldtripDetailPage';
import { HistoryOutingDetailPage } from './HistoryOutingDetailPage';

export interface MergedGroupType {
  id: number;
  name: string;
  type: string;
}

export function HistoryPage() {
  const thisYear = new Date().getFullYear();
  const { replace } = useHistory();
  const queryClient = useQueryClient();
  const { me } = UserContainer.useContext();
  const userRole = me?.role;

  const [_studentName, set_studentName] = useState('');

  const [klassList, setKlassList] = useState<MergedGroupType[]>([]);

  const {
    outings,
    absents,
    fieldtrips,
    isLoading,
    error,
    isViewAuth,
    state: {
      startDate,
      endDate,
      selectedYear,
      selectedMonth,
      selectedGroup,
      page,
      isCsvData,
      limit,
      selectedDocType,
      frontSortType,
    },
    setState: {
      setStartDate,
      setEndDate,
      setSelectedYear,
      setSelectedMonth,
      setSelectedDocType,
      setSelectedGroup,
      setPage,
      setCsvData,
      setOutingId,
      setOpen,
      setAgreeAll,
      setAbsentId,
      setFieldtripId,
    },
    submitAbsent,
    submitNiceAbsent,
  } = useTeacherHistory();

  // useEffect(() => {
  //   if (selectedYear !== -1) {
  //     useTeacherKlassGroup(selectedYear);
  //   }
  // }, [selectedYear]);

  const { allKlassGroupsUnique: allKlassGroups } = useTeacherKlassGroup(selectedYear);

  //useEffect(() => {
  if (allKlassGroups && allKlassGroups.length > 0 && klassList.length === 0) {
    const mergedGroups: MergedGroupType[] = [];

    const gradeRegex = /(\d{1,2})학년/;
    let preGrade = '';
    for (const klassGroup of allKlassGroups) {
      const match = klassGroup.name?.match(gradeRegex);

      if (match) {
        const grade = match[1];

        if (preGrade !== grade) {
          mergedGroups.push({
            id: Number('-' + grade + '00'),
            name: grade.toString() + '학년 전체',
            type: 'KLASS',
          });

          preGrade = grade;
        }
      }

      mergedGroups.push({
        id: klassGroup.id,
        name: klassGroup.name || '',
        type: klassGroup.type,
      });
    }

    setKlassList(mergedGroups);
  }
  //}, [allKlassGroups]);

  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/absent');

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="출결서류관리" left={<BackButton />} />
        <br />
        <div className="flex h-full w-full items-center text-center">
          <p>모바일 환경에서 지원하지 않는 메뉴입니다.</p>
        </div>

        <br />
        <br />
      </div>

      {/* Desktop V */}
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}

      <div className={`col-span-3 h-screen-7 md:h-screen ${isDetail ? 'hidden' : 'block'} md:block`}>
        <div className="md:hidden">
          <TopNavbar
            title="출결서류관리"
            left={<BackButton />}
            right={
              <div onClick={() => queryClient.refetchQueries({ active: true })} className="text-sm text-brand-1">
                <Refresh />
              </div>
            }
          />
        </div>
        <div className="overflow-y-auto ">
          <div className="scroll-box flex flex-col overflow-x-scroll px-6 py-4">
            <div className="hidden md:block">
              <div className="flex items-center justify-between ">
                <h1 className="text-2xl font-semibold">출결서류관리</h1>
                <div
                  className="cursor-pointer text-lg text-brand-1"
                  onClick={() => queryClient.refetchQueries({ active: true })}
                >
                  새로고침
                </div>
              </div>
              <div className="mb-5 text-sm text-grey-5">{`※ 학생증, ${t(`Custom.SID${me?.schoolId}.absentTitle`, {
                defaultValue: '출결신고서',
              })}, 체험학습 과거이력관리`}</div>
            </div>
            <div className="mb-2 flex items-center space-x-3">
              <div className="flex items-center space-x-1" id="menu">
                <Select.lg
                  placeholder="출결서류"
                  value={selectedDocType}
                  onChange={(e) => {
                    setSelectedDocType(Number(e.target.value));
                    if (e.target.value === '2') {
                      setSelectedGroup(null);
                    }
                  }}
                  className="h-11 text-sm"
                >
                  <option value={-1}>{'서류선택'}</option>
                  <option value={0}>{'확인증'}</option>
                  <option value={1}>
                    {t(`Custom.SID${me?.schoolId}.absentTitle`, { defaultValue: '출결신고서' })}
                  </option>
                  <option value={2}>{'체험학습'}</option>
                </Select.lg>

                <Select.lg
                  className="text-sm"
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(Number(e.target.value));
                    const year = Number(e.target.value);
                    const month = selectedMonth;

                    if (month > 0 && year > 0) {
                      const startDate = new Date(year, month - 1, 1);
                      const endDate = new Date(year, month, 0);

                      setStartDate(DateUtil.formatDate(startDate || '', DateFormat['YYYY-MM-DD']));
                      setEndDate(DateUtil.formatDate(endDate || '', DateFormat['YYYY-MM-DD']));
                    }
                  }}
                >
                  <option value={-1}>{'년도선택'}</option>
                  {range(thisYear, thisYear - 4, -1).map((year) => (
                    <option key={year} value={year}>
                      {year}학년도
                    </option>
                  ))}
                </Select.lg>

                <Select.lg
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(Number(e.target.value));
                    const year = selectedYear;
                    const month = Number(e.target.value);

                    if (year > 0 && month > 0) {
                      const startDate = new Date(year, month - 1, 1);
                      const endDate = new Date(year, month, 0);

                      setStartDate(DateUtil.formatDate(startDate || '', DateFormat['YYYY-MM-DD']));
                      setEndDate(DateUtil.formatDate(endDate || '', DateFormat['YYYY-MM-DD']));
                    }
                  }}
                >
                  <option value={-1}>월선택</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                    <option key={month} value={month}>
                      {month}월
                    </option>
                  ))}
                </Select.lg>

                <Select.lg
                  value={selectedGroup?.id || ''}
                  onChange={(e) => {
                    setSelectedGroup(klassList?.find((tg: any) => tg.id === Number(e.target.value)) || null);
                    if (selectedDocType < 0) return;
                  }}
                >
                  <option value={-1}>{'반선택'}</option>
                  {klassList
                    ?.filter((group: MergedGroupType) => group.name !== '0학년 0반')
                    ?.map((group: MergedGroupType) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </Select.lg>
              </div>
            </div>

            <div className="mb-2 ml-3 flex items-center space-x-3">
              <div className="flex items-center space-x-1">
                <SearchInput
                  placeholder="이름 검색"
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') replace(`/teacher/history`);
                    setPage(1);
                  }}
                  onSearch={() => _studentName && replace(`/teacher/history?username=${_studentName}`)}
                  className="w-full"
                />
                <Icon.Search
                  onClick={() => {
                    if (selectedDocType === -1) {
                      alert('서류 종류를 선택해주세요.');
                      return;
                    }

                    if (selectedYear === -1) {
                      alert('년도를 선택해주세요.');
                      return;
                    }

                    if (selectedMonth === -1) {
                      alert('월을 선택해주세요.');
                      return;
                    }

                    _studentName === ''
                      ? alert('텍스트 내용을 입력해주세요.')
                      : replace(`/teacher/history?username=${_studentName}`);
                  }}
                />
              </div>

              <div className="grid auto-cols-fr grid-flow-col gap-2 max-md:hidden">
                {/* 출결신고서 */}
                {selectedDocType === 1 && (
                  <>
                    {/* PDF 버튼 */}
                    <AbsentsDownloadView
                      nowDate={`${DateUtil.formatDate(
                        new Date(startDate),
                        DateFormat['YYYY/MM/DD'],
                      )}~${DateUtil.formatDate(new Date(endDate), DateFormat['YYYY/MM/DD'])}`}
                      absents={absents?.items
                        ?.slice()
                        // .filter((absent) =>
                        //   selectedGroup
                        //     ? absent?.studentGradeKlass === selectedGroup?.name &&
                        //       absent?.absentStatus === AbsentStatus.PROCESSED &&
                        //       isViewAuth
                        //     : absent?.absentStatus === AbsentStatus.PROCESSED && isViewAuth,
                        // )
                        .filter(
                          (absent) =>
                            new Date(absent.endAt).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0) &&
                            new Date(absent.startAt).setHours(0, 0, 0, 0) <= new Date(endDate).setHours(0, 0, 0, 0),
                        )
                        .sort((a, b) => new Date(a?.startAt || '').getTime() - new Date(b?.startAt || '').getTime())}
                      setCsvData={(b: boolean) => setCsvData(b)}
                      isCsvData={isCsvData}
                    />
                    {/* Excel 버튼 */}
                    <AbsentsExcelDownloadView
                      startDate={startDate}
                      endDate={endDate}
                      selectedGroupId={selectedGroup && selectedGroup.id ? selectedGroup.id : undefined}
                    />
                  </>
                )}
                {/* 확인증 */}
                {selectedDocType === 0 && (
                  <>
                    {/* 확인증현황 Excel 버튼 */}
                    <div className="hidden items-center md:block">
                      <OutingsExcelDownloadView
                        startDate={startDate}
                        endDate={endDate}
                        selectedGroupId={selectedGroup && selectedGroup.id ? selectedGroup.id : undefined}
                        username={_studentName}
                      />
                    </div>
                  </>
                )}
                {/* 체험학습 */}
                {selectedDocType === 2 && (
                  <>
                    <div className=" hidden items-center md:block">
                      <FieldtripsDownloadView
                        nowDate={`${DateUtil.formatDate(
                          new Date(startDate),
                          DateFormat['YYYY/MM/DD'],
                        )}~${DateUtil.formatDate(new Date(endDate), DateFormat['YYYY/MM/DD'])}`}
                        fieldtrips={fieldtrips?.items
                          ?.slice()
                          .sort((a, b) => {
                            return a.startAt < b.startAt ? -1 : a.startAt > b.startAt ? 1 : 0;
                          })
                          .filter((fieldtrip) => me?.role && fieldtrip?.fieldtripResultStatus === 'PROCESSED')
                          .filter(
                            (fieldtrip) =>
                              new Date(fieldtrip.endAt).setHours(0, 0, 0, 0) >=
                                new Date(startDate).setHours(0, 0, 0, 0) &&
                              new Date(fieldtrip.startAt).setHours(0, 0, 0, 0) <=
                                new Date(endDate).setHours(0, 0, 0, 0),
                          )}
                        setCsvData={(b: boolean) => setCsvData(b)}
                        isCsvData={isCsvData}
                      />
                    </div>
                    <div className="hidden items-center md:block">
                      {/* 체험학습현황 Excel 버튼 */}
                      <FieldtripExcelDownloadView
                        startDate={startDate}
                        endDate={endDate}
                        fieldtripStatus={'PROCESSED'}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="h-0.5 bg-gray-100"></div>
          </div>

          <div className="scroll-box h-screen-16  overflow-y-auto">
            {/* 출결신고서 */}
            {selectedDocType === 1 &&
              absents?.items
                .filter(
                  (absent) =>
                    new Date(absent.endAt).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0) &&
                    new Date(absent.startAt).setHours(0, 0, 0, 0) <= new Date(endDate).setHours(0, 0, 0, 0),
                )
                .sort((a, b) => new Date(a?.startAt || '').getTime() - new Date(b?.startAt || '').getTime())
                .map((absent) => (
                  <AbsentCard
                    key={absent.id}
                    absent={absent}
                    submitAbsent={submitAbsent}
                    submitNiceAbsent={submitNiceAbsent}
                    page={page}
                    limit={limit}
                    type={'history'}
                  />
                ))}
            {selectedDocType === 1 && absents && absents?.total > limit && (
              <div className="grid place-items-center">
                <FrontPagination
                  basePath="/teacher/absent"
                  total={absents?.total}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                />
              </div>
            )}

            {/* 확인증 */}
            {selectedDocType === 0 &&
              // eslint-disable-next-line react/jsx-no-undef
              outings?.items?.map((outing: ResponseCreateOutingDto) => (
                <OutingCard key={outing.id} outing={outing} type={'history'} />
              ))}
            {selectedDocType === 0 && outings && outings?.total > limit && (
              <div className="grid place-items-center">
                <FrontPagination
                  basePath="/teacher/outing"
                  total={outings?.total}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                />
              </div>
            )}

            {/* 체험학습 */}

            {selectedDocType === 2 &&
              fieldtrips?.items
                ?.filter(
                  (fieldtrip) =>
                    new Date(fieldtrip.endAt).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0) &&
                    new Date(fieldtrip.startAt).setHours(0, 0, 0, 0) <= new Date(endDate).setHours(0, 0, 0, 0),
                )
                .sort((a, b) => {
                  return a.startAt < b.startAt ? -1 : a.startAt > b.startAt ? 1 : 0;
                })
                .map(
                  (fieldtrip) =>
                    me?.role && <FieldtripCard key={fieldtrip.id} fieldtrip={fieldtrip} type={'history'} />,
                )}

            {fieldtrips && fieldtrips.total > limit && (
              <div className="grid min-w-max place-items-center">
                <FrontPagination
                  basePath="/teacher/fieldtrip"
                  total={fieldtrips.total}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="scroll-box col-span-3 overflow-y-auto md:bg-gray-50">
        <Switch>
          <Route
            path="/teacher/history/:id"
            component={() =>
              selectedDocType === 0 ? (
                <HistoryOutingDetailPage
                  outings={outings}
                  setOutingId={(n: number) => setOutingId(n)}
                  setOpen={(b: boolean) => setOpen(b)}
                  setAgreeAll={(b: boolean) => setAgreeAll(b)}
                  userRole={userRole}
                />
              ) : selectedDocType === 1 ? (
                <HistoryAbsentDetailPage
                  userId={me?.id}
                  setOpen={(b: boolean) => setOpen(b)}
                  setAbsentId={(n: number) => setAbsentId(n)}
                  setAgreeAll={(b: boolean) => setAgreeAll(b)}
                />
              ) : (
                <HistoryFieldtripDetailPage
                  setOpen={(b: boolean) => setOpen(b)}
                  setFieldtripId={(n: number) => setFieldtripId(n)}
                  setAgreeAll={(b: boolean) => setAgreeAll(b)}
                  me={me}
                />
              )
            }
          />
        </Switch>
      </div>
    </>
  );
}
