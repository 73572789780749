import { Link, useLocation } from 'react-router-dom';
import { Badge } from 'src/components/common';
import { FieldtripStatus, ResponsePaginatedFieldtripDto } from 'src/generated/model';
import { makeStartEndToString } from 'src/util/time';

interface FieldtripCardProps {
  fieldtrip: ResponsePaginatedFieldtripDto['items'][number];
  type: string;
}

export function FieldtripCard({ fieldtrip, type }: FieldtripCardProps) {
  const { pathname, search } = useLocation();
  let text;
  let textResult;

  switch (fieldtrip?.fieldtripStatus) {
    case FieldtripStatus.BEFORE_PARENT_CONFIRM:
      text = <div className="text-sm text-red-500">학부모 승인 전</div>;
      break;
    case FieldtripStatus.PROCESSING:
      text = <div className="text-sm text-red-500">{fieldtrip?.nextApproverTitle} 승인 전</div>;
      break;
    case FieldtripStatus.PROCESSED:
      text = <div className="text-sm text-gray-600">승인 완료</div>;
      break;
    case FieldtripStatus.RETURNED:
      text = <div className="text-sm text-brand-1">반려됨</div>;
      break;
    case FieldtripStatus.DELETE_APPEAL:
      text = <div className="text-sm text-red-800">삭제 요청</div>;
      break;
  }

  if (fieldtrip?.fieldtripStatus === FieldtripStatus.PROCESSED) {
    switch (fieldtrip?.fieldtripResultStatus) {
      case FieldtripStatus.BEFORE_PARENT_CONFIRM:
        textResult = fieldtrip?.resultTitle ? (
          <div className="text-xs text-red-500">결과보고서 : 학부모 승인 전</div>
        ) : (
          <div className="text-xs text-grey-3">결과보고서 : 작성 전</div>
        );
        break;
      case FieldtripStatus.PROCESSING:
        textResult = (
          <div className="text-xs text-red-500">결과보고서 : {fieldtrip?.nextResultApproverTitle} 승인 전</div>
        );
        break;
      case FieldtripStatus.PROCESSED:
        textResult = <div className="text-xs text-gray-600">결과보고서 : 승인 완료</div>;
        break;
      case FieldtripStatus.RETURNED:
        textResult = <div className="text-xs text-brand-1">결과보고서 : 반려됨</div>;
        break;
      case FieldtripStatus.DELETE_APPEAL:
        textResult = <div className="text-xs text-red-800">결과보고서 : 삭제 요청</div>;
        break;
      default:
        textResult = <div className="text-xs text-grey-3">결과보고서 : 작성 전</div>;
        break;
    }
  }

  return (
    <>
      <Link to={`/teacher/${type}/${fieldtrip.id}${search}`}>
        <div
          className={
            pathname.startsWith(`/teacher/fieldtrip/${fieldtrip.id}`)
              ? 'relative flex w-full items-center justify-between bg-gray-50 p-4'
              : 'relative flex w-full items-center justify-between p-4'
          }
        >
          <div>
            <h3 className="text-lg font-semibold">
              [신청서] {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
            </h3>
            <div className="my-1 flex space-x-2 text-sm font-semibold">
              {fieldtrip?.type === 'HOME' ? (
                <Badge children="가정" className="bg-brand-5 text-brand-1" />
              ) : (
                <Badge children="교외" className="bg-brand-5 text-brand-1" />
              )}

              <div>{fieldtrip.type === 'SUBURBS' ? fieldtrip?.form || '교외체험학습' : '가정학습'}</div>
            </div>
            <div className="pt-1 text-xs text-gray-500">
              기간 :{' '}
              {fieldtrip?.startAt && fieldtrip?.endAt && makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
            </div>
            <div className="pt-1 text-xs text-gray-500">신청일 : {fieldtrip.reportedAt}</div>
          </div>
          <div className="text-right">
            {text}
            <br />
            {textResult}
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
}
