import { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import BgLogo from 'src/assets/images/Intersect.png';
import SvgUser from 'src/assets/svg/user.svg';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, IconButton, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { Constants } from 'src/constants';
import { GroupContainer } from 'src/container/group';
import { useTeacherInfoUpdate } from 'src/container/teacher-info-update';
import { UploadFileTypeEnum } from 'src/generated/model';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { meState } from 'src/store';
import { checkFileSizeLimit20MB } from 'src/util/file';
import { getHoursfromHHmmString, getMinutesfromHHmmString } from 'src/util/time';
import { Validator } from 'src/util/validator';

export function TeacherInfoPage() {
  const meRecoil = useRecoilValue(meState);
  const { allTeacherGroups, errorGroups } = GroupContainer.useContext();
  const [isUpdateMe, setIsUpdateMe] = useState(false);
  const [name, setName] = useState(meRecoil?.name);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState(meRecoil?.phone || '');
  const [profile, setProfile] = useState(meRecoil?.profile || '');
  const [department, setDepartment] = useState(meRecoil?.teacherProperty?.department || '');
  const [position, setPosition] = useState(meRecoil?.teacherProperty?.position || '');
  const { handleUploadFile, isUploadLoading } = useFileUpload();
  const { isUpdateMeLoading, updateMe } = useTeacherInfoUpdate();

  const [enableChatTime, setEnableChatTime] = useState(true);
  const [startH, setStartH] = useState(0);
  const [startM, setStartM] = useState(0);
  const [endH, setEndH] = useState(0);
  const [endM, setEndM] = useState(0);

  const spacedName = (name: string) => {
    return name.split('').join('  ');
  };

  useEffect(() => {
    if (meRecoil?.teacherProperty) {
      setStartH(getHoursfromHHmmString(meRecoil?.teacherProperty?.chatStartTime, 9));
      setStartM(getMinutesfromHHmmString(meRecoil?.teacherProperty?.chatStartTime, 0));
      setEndH(getHoursfromHHmmString(meRecoil?.teacherProperty?.chatEndTime, 18));
      setEndM(getMinutesfromHHmmString(meRecoil?.teacherProperty?.chatEndTime, 0));
      setEnableChatTime(meRecoil?.teacherProperty?.chatStartTime !== meRecoil?.teacherProperty?.chatEndTime);
    }
  }, []);

  const buttonDisabled = !name || !phone || password !== password2;

  const handleUpdate = () => {
    updateMe({
      name,
      phone,
      password,
      profile: profile || '',
      position,
      department,
      chatStartTime: enableChatTime ? startH + ':' + startM.toString().padStart(2, '0') : '00:00',
      chatEndTime: enableChatTime ? endH + ':' + endM.toString().padStart(2, '0') : '00:00',
    })
      .then(() => {
        setIsUpdateMe(false);
      })
      .catch((e) => {
        console.log('update error', e);
      });
  };

  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!Validator.fileNameRule(selectedImageFiles[0].name)) {
      alert('특수문자(%, &, ?, ~, +)가 포함된 파일명은 사용할 수 없습니다.');
      return;
    }

    if (!checkFileSizeLimit20MB([selectedImageFiles[0]])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다.');
      return;
    }

    const imageFileNames = await handleUploadFile(UploadFileTypeEnum['userpictures'], [selectedImageFiles[0]]);

    setProfile(imageFileNames[0]);
  };

  if (!meRecoil || errorGroups) {
    return <ErrorBlank />;
  }

  return (
    <div className="col-span-6">
      <div className="block md:hidden">
        <TopNavbar title="내 정보" left={<BackButton />} />
      </div>
      <Section className="flex h-screen-6 flex-col bg-gray-50 py-0 pb-4 md:h-screen">
        {isUpdateMeLoading && <Blank />}
        <div className="mx-0 overflow-y-scroll bg-white md:mx-40 md:h-screen">
          {/* 교원증 영역 */}
          <div className="mt-4 flex h-[428px] justify-center md:mt-16 md:px-10">
            <div className="relative w-80 rounded-lg border border-zinc-100 bg-white shadow">
              <div className="flex h-12 items-center justify-center rounded-t-lg bg-black">
                <div className="text-center font-['Pretendard'] text-xl font-semibold leading-loose tracking-widest text-white">
                  교&nbsp;&nbsp;원&nbsp;&nbsp;증
                </div>
              </div>
              <div className="flex w-full flex-col items-center bg-white">
                <div className="relative z-10 mb-4 mt-6 w-40 rounded-lg md:mt-8">
                  {isUpdateMe ? (
                    <label htmlFor="imageupload" className="h-48 w-40 cursor-pointer">
                      <img
                        className="h-48 w-40 rounded-lg object-cover"
                        src={`${Constants.imageUrl}${profile}`}
                        alt=""
                        loading="lazy"
                      />
                      <div className="absolute left-1/2 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-brand-1">
                        <p className="text-2xl text-white">+</p>
                      </div>
                      <input
                        type="file"
                        id="imageupload"
                        accept=".pdf, .png, .jpeg, .jpg"
                        onChange={(e) => handleChangeImage(e)}
                        className="hidden"
                      />
                    </label>
                  ) : (
                    <img
                      src={`${Constants.imageUrl}${profile}`}
                      alt="프로필 이미지"
                      loading="lazy"
                      className="h-48 w-40 rounded-lg object-cover"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = SvgUser;
                        currentTarget.className = 'w-full';
                      }}
                    />
                  )}
                </div>
                <img className="absolute bottom-10 right-0 h-auto w-auto" src={BgLogo} alt="" />
                <p className="z-10 text-2xl font-bold">{spacedName(meRecoil?.name)}</p>
                <p className="z-10 mt-1">{meRecoil?.email}</p>
                <div className="flex flex-row items-center justify-center gap-1 pt-6">
                  {meRecoil?.school.mark && (
                    <IconButton
                      children={<img src={`${Constants.imageUrl}${meRecoil?.school.mark}`} alt="" loading="lazy" />}
                      className="h-8 w-8"
                    />
                  )}
                  <p className="text-black">{meRecoil?.school.name}</p>
                </div>
              </div>
            </div>
          </div>
          {/* 내 정보 영역 */}
          <div className="mt-16 px-4 py-4 md:px-10 md:py-5">
            <div className="flex items-center justify-between">
              <div className="whitespace-pre text-24 font-bold">내 정보</div>
              {isUpdateMe ? (
                <div className="flex items-center gap-2">
                  <Button className="h-8 w-16 border border-gray-600" onClick={() => setIsUpdateMe(!isUpdateMe)}>
                    취소
                  </Button>
                  <Button
                    className="h-8 w-16 bg-zinc-800 text-white"
                    disabled={buttonDisabled}
                    onClick={() => {
                      if (!Validator.phoneNumberRule(phone)) {
                        alert('전화번호를 확인해 주세요.');
                        return;
                      }
                      if (password.length === 0) {
                        alert('비밀번호를 공백으로 기입할 시 기존 비밀번호가 유지됩니다.');
                        handleUpdate();
                      } else {
                        if (password !== password2) {
                          alert('비밀번호와 비밀번호 확인이 다릅니다.');
                          return;
                        }
                        if (!Validator.passwordRule(password)) {
                          alert('안전한 비밀번호를 입력하세요.');
                          return;
                        }
                        handleUpdate();
                        setIsUpdateMe(!isUpdateMe);
                      }
                    }}
                  >
                    저장
                  </Button>
                </div>
              ) : (
                <Button className="h-8 w-16 border border-gray-600" onClick={() => setIsUpdateMe(!isUpdateMe)}>
                  수정
                </Button>
              )}
            </div>

            <div className="mt-4 border border-zinc-300">
              <div className="flex flex-col rounded-sm xl:grid xl:grid-cols-2">
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">이름</label>
                  {isUpdateMe ? (
                    <>
                      <input
                        className="flex w-30 flex-1 rounded-none border border-zinc-300 px-4 underline underline-offset-4 focus:border-zinc-300"
                        placeholder="이름을 입력해주세요"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </>
                  ) : (
                    <div className="flex flex-1 border border-zinc-300 p-4">{meRecoil?.name}</div>
                  )}
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">이메일</label>
                  <div className="flex flex-1 border border-zinc-300 p-4">{meRecoil?.email}</div>
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">전화번호</label>
                  {isUpdateMe ? (
                    <>
                      <input
                        className="flex w-30 flex-1 rounded-none border border-zinc-300 px-4 underline underline-offset-4 focus:border-zinc-300"
                        placeholder="전화번호를 입력해주세요"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </>
                  ) : (
                    <div className="flex flex-1 border border-zinc-300 p-4">
                      {meRecoil?.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
                    </div>
                  )}
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">학교</label>
                  <div className="flex flex-1 border border-r border-zinc-300 border-r-zinc-300 p-4">
                    {meRecoil?.school.name}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">부서</label>
                  {isUpdateMe ? (
                    <>
                      <input
                        className="flex w-30 flex-1 rounded-none border border-zinc-300 px-4 underline underline-offset-4 focus:border-zinc-300"
                        placeholder="부서를 입력해주세요"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                      />
                    </>
                  ) : (
                    <div className="flex flex-1 border border-zinc-300 p-4">
                      {meRecoil?.teacherProperty?.department}
                    </div>
                  )}
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">직책</label>
                  {isUpdateMe ? (
                    <>
                      <input
                        className="flex w-30 flex-1 rounded-none border border-zinc-300 px-4 underline underline-offset-4 focus:border-zinc-300"
                        placeholder="직책을 입력해주세요"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </>
                  ) : (
                    <div className="flex flex-1 border border-zinc-300 p-4">{meRecoil?.teacherProperty?.position}</div>
                  )}
                </div>
              </div>
              <div className="flex">
                <label className="flex w-24 items-center border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">
                  대화 가능시간
                </label>
                <div className="flex flex-1 flex-col border border-zinc-300 px-2 2xl:flex-row">
                  {isUpdateMe ? (
                    <div className="flex flex-1 flex-col items-start justify-between md:flex-row md:items-center">
                      <div className="flex flex-col gap-2 p-4">
                        <div className="flex flex-col items-center space-x-2 md:flex-row">
                          <div className="items flex items-center justify-between gap-1">
                            <select
                              value={startH}
                              onChange={(e) => setStartH(Number(e.target.value))}
                              disabled={!enableChatTime}
                              className="w-14 min-w-max appearance-none border-0 border-b-2
               border-gray-200 px-0 py-1 placeholder-gray-400 focus:border-gray-200 focus:outline-none
               focus:ring-0 
               disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                            >
                              {new Array(24).fill(null).map((item, num: number) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              ))}
                            </select>
                            <span>:</span>
                            <select
                              value={startM}
                              onChange={(e) => setStartM(Number(e.target.value))}
                              disabled={!enableChatTime}
                              className="w-14 min-w-max appearance-none border-0 border-b-2
               border-gray-200 px-0 py-1 placeholder-gray-400 focus:border-gray-200 focus:outline-none
               focus:ring-0 
               disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                            >
                              <option value={0}>00</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="items flex items-center justify-between gap-1">
                            <span>~</span>
                            <select
                              value={endH}
                              onChange={(e) => setEndH(Number(e.target.value))}
                              disabled={!enableChatTime}
                              className="w-14 min-w-max appearance-none border-0 border-b-2
               border-gray-200 px-0 py-1 placeholder-gray-400 focus:border-gray-200 focus:outline-none
               focus:ring-0 
               disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                            >
                              {new Array(24).fill(null).map((item, num: number) => (
                                <option key={num} value={num}>
                                  {num}
                                </option>
                              ))}
                            </select>
                            <span>:</span>
                            <select
                              className="w-14 min-w-max appearance-none border-0 border-b-2
                      border-gray-200 px-0 py-1 placeholder-gray-400 focus:border-gray-200 focus:outline-none
                      focus:ring-0 
                      disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                              onChange={(e) => setEndM(Number(e.target.value))}
                              disabled={!enableChatTime}
                              value={endM}
                            >
                              <option value={0}>00</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Checkbox checked={enableChatTime} onChange={() => setEnableChatTime(!enableChatTime)} />
                          <div className="text-stone-500">대화 가능시간 사용</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-1 p-2 md:flex-row md:items-center">
                      <div className="flex items-center">
                        {meRecoil?.teacherProperty?.chatStartTime &&
                        meRecoil?.teacherProperty?.chatEndTime &&
                        meRecoil?.teacherProperty?.chatStartTime !== meRecoil?.teacherProperty?.chatEndTime ? (
                          <div className="">
                            {meRecoil?.teacherProperty?.chatStartTime} ~ {meRecoil?.teacherProperty?.chatEndTime}
                          </div>
                        ) : (
                          <div className="font-['Pretendard'] text-base font-normal leading-normal text-neutral-400">
                            대화 가능시간이 설정되어 있지 않습니다.
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {(isUpdateMe ||
                    meRecoil?.teacherProperty?.chatStartTime !== meRecoil?.teacherProperty?.chatEndTime) && (
                    <div className="flex flex-col p-3 md:flex-row md:items-center md:justify-between md:p-4">
                      <div className="text-sm text-orange-500">
                        <p>* 새 대화 시작 시 설정되는 시간입니다.</p>
                        <p>* 대화 시작 후 대화방마다 별도의 시간을 설정할 수 있습니다.</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex w-full">
                <label className="flex w-24 items-center border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">
                  담당 그룹
                </label>
                <div className="flex flex-1 flex-wrap gap-2 border-y border-r border-y-zinc-300 border-r-zinc-300 p-4">
                  {allTeacherGroups?.map((tg) => (
                    <div key={tg.id} className="w-full rounded bg-orange-100 px-2 py-1 md:w-[calc(50%-0.5rem)]">
                      {tg.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {isUpdateMe && (
            <>
              <div className="mt-4 flex flex-col rounded-sm px-4 py-2 md:grid md:grid-cols-2 md:px-10 md:py-5">
                <div className="flex flex-1">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">비밀번호</label>
                  <input
                    className="flex flex-1 rounded-none border border-zinc-300 underline underline-offset-4 focus:border-zinc-300 focus:ring-0"
                    type="password"
                    placeholder="비밀번호를 입력해 주세요"
                    value={password}
                    onChange={(e) => setPassword(Validator.removeSpace(String(e.target.value)))}
                  />
                </div>
                <div className="flex">
                  <label className="w-24 border border-zinc-300 bg-stone-50 p-4 font-semibold md:w-32">
                    비밀번호 확인
                  </label>
                  <input
                    type="password"
                    className="flex flex-1 rounded-none border border-zinc-300 underline underline-offset-4 focus:border-zinc-300 focus:ring-0"
                    placeholder="비밀번호를 한번 더 입력해 주세요"
                    value={password2}
                    onChange={(e) => setPassword2(Validator.removeSpace(String(e.target.value)))}
                  />
                </div>
              </div>
              <div className="mt-2 px-4 py-2 text-sm md:px-10 md:py-5">
                <p className="flex items-center gap-1">
                  <p className="text-orange-500 ">*</p> 안전한 개인정보의 보호를 위해 문자,숫자,특수문자가 포함된 8자
                  이상의 비밀번호를 입력하세요.
                </p>
                <p className="flex items-center gap-1">
                  <p className="text-orange-500 ">*</p> 사용 가능한 특수문자는 ! @ # $ % & * ? 입니다.
                </p>
                <p className="flex items-center gap-1">
                  <p className="text-orange-500 ">*</p> 비밀번호를 입력하지 않으면 기존 비밀번호가 유지 됩니다.
                </p>
              </div>
            </>
          )}
        </div>
      </Section>
    </div>
  );
}
