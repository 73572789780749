import { TextareaAutosize } from '@mui/material';
import _ from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRecoilState } from 'recoil';
import SvgUser from 'src/assets/svg/user.svg';
import { Label, Radio, RadioGroup } from 'src/components/common';
import { Checkbox } from 'src/components/common/Checkbox';
import { Coachmark2 } from 'src/components/common/CoachMark2';
import ConfirmDialog from 'src/components/common/ConfirmDialog';
import { TextInput } from 'src/components/common/TextInput';
import { Icon } from 'src/components/common/icons';
import { Constants } from 'src/constants';
import {
  useAchievementCriteriaGetByIds,
  useActivityV3GetGPTReport,
  useStudentRecordontrollerCreate,
} from 'src/generated/endpoint';
import { ActivityV3, ResponseStudentCardStudentDto, SubjectType } from 'src/generated/model';
import { toastState } from 'src/store';
import 'swiper/swiper.min.css';

interface ActivityV3GPTModalProps {
  activityV3s?: ActivityV3[];
  checkedCardIds: number[];
  setCheckedCardIds: (cardIds: number[]) => void;
  onClose: () => void;
  studentInfo: ResponseStudentCardStudentDto;
  refetch: () => void;
}

export const ActivityV3GPTModal: FC<ActivityV3GPTModalProps> = ({
  activityV3s = [],
  setCheckedCardIds,
  checkedCardIds,
  onClose,
  studentInfo,
  refetch,
}) => {
  const [toastMsg, setToastMsg] = useRecoilState(toastState);
  const [currentStep, setCurrentStep] = useState(1);
  const _content = localStorage.getItem('student_record_content');
  const _type = localStorage.getItem('student_record_type');
  const _achievement = localStorage.getItem('student_record_achievement');

  const [title, setTitle] = useState('');
  const [content, setContent] = useState(_content || '');
  const [type, _setType] = useState<string>(_type || '');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const titleInputRef = useRef<HTMLInputElement>(null);
  const setType = (type: string) => {
    _setType(type);
    localStorage.setItem('student_record_type', type);
  };
  const [achievement, _setAchievement] = useState<string>(_achievement || '상');
  const setAchievement = (ach: string) => {
    _setAchievement(ach);
    localStorage.setItem('student_record_achievement', ach);
  };
  const selectedActivityV3s = activityV3s?.filter((av3) => checkedCardIds.includes(av3.id));
  const [coachmarkVisible, setCoachmarkVisible] = useState<boolean>(true);
  const trimmedContent = content.replaceAll(' ', '');
  const byteLength = new TextEncoder().encode(trimmedContent).length;

  useEffect(() => {
    const hasSeenCoachmark = localStorage.getItem('GPTIsFirst');
    if (hasSeenCoachmark) {
      setCoachmarkVisible(false);
    }
  }, []);

  const handleCoachmarkClose = () => {
    setCoachmarkVisible(false);
    localStorage.setItem('GPTIsFirst', 'not');
  };

  const handleCoachmarOpen = () => {
    setCoachmarkVisible(true);
    localStorage.removeItem('GPTIsFirst');
    setCurrentStep(1);
  };

  const selectedCriteriaIds = selectedActivityV3s.reduce(
    (acc: number[], cur) => acc.concat(cur.achievementCriteriaIds),
    [],
  );

  const { data: achievementCriterias } = useAchievementCriteriaGetByIds(
    {
      ids: selectedCriteriaIds,
    },
    {
      query: {
        enabled: !!selectedCriteriaIds.length,
      },
    },
  );

  const { mutate: getGptReport, isLoading } = useActivityV3GetGPTReport({
    mutation: {
      onMutate: () => {
        setContent('');
        localStorage.setItem('student_record_content', '');
      },
      onSuccess: (data: any) => {
        try {
          const reportContent = data.report.map((el: any) => el.content).join('\n');
          setContent(reportContent);
          localStorage.setItem('student_record_content', reportContent);
        } catch (err) {
          console.log('error : ', err);
        }
      },
      onError: (error) => {
        setToastMsg(error.message);
      },
    },
  });

  const { mutate: createStudentRecord } = useStudentRecordontrollerCreate({
    mutation: {
      onSuccess: () => {
        setToastMsg('변경 사항이 저장되었습니다.');
        onClose();
        setContent('');
        localStorage.setItem('student_record_content', '');
        localStorage.setItem('student_record_type', '');
        setType('');
        setTitle('');
        setAchievement('');
        setCheckedCardIds([]);
        refetch();
      },
      onError: (error) => {
        setToastMsg(error.message);
      },
    },
  });

  const handleSaveClick = () => {
    if (!title) {
      if (titleInputRef.current) {
        titleInputRef.current.focus();
        alert('활동기록부 초안의 제목을 입력해 주세요.');
      }
      return;
    }

    createStudentRecord({
      data: {
        content,
        type,
        title,
      },
      params: { studentId: studentInfo.id },
    });
  };

  const steps = [
    {
      title: '1단계 : 활동리스트',
      description: '학생이 참여하고 있는 활동 리스트가 나타나고, 체크박스를 통해 추가 및 제거가 가능합니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '2단계 : 활동기록부 종류 선택',
      description: '활동기록부 항목 종류(교과/창체)를 선택합니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '3단계 : 선택한 활동',
      description: '선택한 활동 중 지우고 싶은 항목을 클릭하여 제외할 수 있습니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '4단계 : 성취수준 선택',
      description:
        '성취수준을 선택합니다. 학생이 교과목 성취기준에 달성한 정도에 따라 상/중/하 중 하나를 선택합니다. 선택한 성취수준에 따라 키워드, 글자 수 등의 차이로 다른 결과가 도출됩니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '다음으로', onClick: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length)) },
      ],
    },
    {
      title: '5단계 : 성취기준 확인',
      description:
        '선택한 활동에 해당되는 성취기준이 자동으로 입력됩니다. 활동 생성 시 성취기준을 설정하지 않은 경우 활동 수정을 통해 설정할 수 있습니다.',
      actions: [
        { text: '그만보기', onClick: () => handleCoachmarkClose() },
        { text: '이전으로', onClick: () => setCurrentStep((prev) => Math.max(prev - 1, 1)) },
        { text: '마치기', onClick: () => handleCoachmarkClose() },
      ],
    },
  ];
  const getIsSessionNotSubmitted = (activityv3: ActivityV3) => {
    if (!activityv3.activitySessions?.length) {
      return false;
    }
    let isSubmitted = false;
    activityv3.activitySessions.map((session) => {
      if (session?.studentActivitySessions?.[0]?.isSubmitted) {
        isSubmitted = true;
      }
    });
    return !isSubmitted;
  };

  const lectureAV3 = _.chain(activityV3s || [])
    .filter(['type', SubjectType.LECTURE])
    .sortBy(['subject'])
    .value();
  const activityAV3 = _.chain(activityV3s || [])
    .filter(['type', SubjectType.ACTIVITY])
    .sortBy(['subject'])
    .value();
  const etcAV3 = _.chain(activityV3s || [])
    .filter(['type', SubjectType.ETC])
    .sortBy(['subject'])
    .value();
  return (
    <div className="flex h-full w-full flex-row">
      {/* 좌측 영역 - 학생 기본 정보 */}
      <section className="flex h-full min-w-[25%] max-w-[260px] flex-col space-y-2 rounded-l-lg bg-white px-6 py-4 pl-6">
        <Icon.AI className="h-20 w-24" />
        <div className="mt-2 flex flex-col items-start gap-2 border-b border-gray-300 pb-4">
          <p className="font-bold">학생 정보</p>
          <div className="flex flex-row items-center gap-4">
            <LazyLoadImage
              className="h-24 w-24 rounded-xl border border-gray-300"
              src={studentInfo?.profile ? `${Constants.imageUrl}${studentInfo.profile}` : SvgUser}
              alt=""
              loading="lazy"
            />
            <div className="flex h-full flex-col justify-center gap-1 text-xs lg:text-14">
              <div className="flex flex-row items-center gap-2">
                <p className="font-semibold">이름</p>
                <p>{studentInfo.name}</p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className=" font-semibold">학번</p>
                <p>
                  {studentInfo.klassGroupName} {studentInfo.studentNumber}번
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <p className=" font-semibold">진로</p>
                <p>{studentInfo.hopePath}</p>
              </div>
              <div className="flex flex-row gap-2">
                <p className=" font-semibold">진학</p>
                <p>{studentInfo.hopeMajor}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full gap-x-0.5">
          <p className="pt-2 font-bold">참여 활동</p>
          {currentStep === 1 && coachmarkVisible && (
            <span className="relative flex h-1.5 w-1.5">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
              <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
              <Coachmark2 steps={steps} currentStep={currentStep} position="top" arrowDirection="bottom" />
            </span>
          )}
        </div>

        <div className="mt-1 flex h-full flex-col space-y-2 overflow-y-auto px-1">
          <div className="-mx-1  font-bold text-brandblue-1">교과</div>
          <div className="flex flex-col space-y-3">
            {lectureAV3.map(
              (av3) =>
                !av3.studentActivityV3s?.[0]?.studentText &&
                getIsSessionNotSubmitted(av3) && (
                  <div key={av3.id} className="flex items-center space-x-2">
                    <Checkbox
                      checked={checkedCardIds.includes(av3.id)}
                      onChange={() =>
                        checkedCardIds.includes(av3.id)
                          ? setCheckedCardIds(checkedCardIds.filter((el) => el !== av3.id))
                          : setCheckedCardIds(checkedCardIds.concat(av3.id))
                      }
                    />
                    <div className="font-semibold">
                      <span className="text-brand-1">[{av3.subject}]</span>&nbsp;
                      {av3.title}
                    </div>
                  </div>
                ),
            )}
          </div>
          <div className="-mx-1 pt-4  font-bold text-brandblue-1">창체</div>
          <div className="flex flex-col space-y-3">
            {activityAV3.map(
              (av3) =>
                !av3.studentActivityV3s?.[0]?.studentText &&
                getIsSessionNotSubmitted(av3) && (
                  <div key={av3.id} className="flex items-center space-x-2">
                    <Checkbox
                      checked={checkedCardIds.includes(av3.id)}
                      onChange={() =>
                        checkedCardIds.includes(av3.id)
                          ? setCheckedCardIds(checkedCardIds.filter((el) => el !== av3.id))
                          : setCheckedCardIds(checkedCardIds.concat(av3.id))
                      }
                    />
                    <div className="font-semibold">
                      <span className="text-brand-1">[{av3.subject}]</span>&nbsp;
                      {av3.title}
                    </div>
                  </div>
                ),
            )}
          </div>
          <div className="-mx-1 pt-4  font-bold text-brandblue-1">기타</div>
          <div className="flex flex-col space-y-3">
            {etcAV3.map(
              (av3) =>
                !av3.studentActivityV3s?.[0]?.studentText &&
                getIsSessionNotSubmitted(av3) && (
                  <div key={av3.id} className="flex items-center space-x-2">
                    <Checkbox
                      checked={checkedCardIds.includes(av3.id)}
                      onChange={() =>
                        checkedCardIds.includes(av3.id)
                          ? setCheckedCardIds(checkedCardIds.filter((el) => el !== av3.id))
                          : setCheckedCardIds(checkedCardIds.concat(av3.id))
                      }
                    />
                    <div className="font-semibold">
                      <span className="text-brand-1">[{av3.subject}]</span>&nbsp;
                      {av3.title}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </section>

      {/* 우측 영역 - 활동 초안 부분 */}
      <section className="flex h-full min-w-[75%] max-w-[500px] overflow-visible rounded-r-lg bg-[#e8ebf7] px-6 py-4">
        <div className="flex w-full rounded-lg bg-white">
          <div
            className={`flex h-full w-2/5 ${!content && !isLoading && 'w-3/5'} flex-col border-r border-gray-300 py-5`}
          >
            <div className="mb-2 h-full w-full overflow-y-scroll px-5 pb-4">
              <div className="flex items-center gap-2">
                <div className="text-xl font-bold">
                  활동기록부 초안 작성<span className="ml-1 align-top text-sm text-brandblue-1">AI</span>
                </div>
                <div
                  className="text-md flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border border-gray-500 text-sm"
                  onClick={() => handleCoachmarOpen()}
                >
                  ?
                </div>
              </div>
              <div className="text-xs font-semibold text-[#666]">
                선택한 활동(들)을 기반으로 활동기록부 초안을 작성합니다.
              </div>

              <div className="mt-6 flex flex-grow flex-col gap-2">
                <div className="flex w-full gap-x-0.5">
                  <div className="text-lg font-semibold">종류</div>
                  {currentStep === 2 && coachmarkVisible && (
                    <span className="relative flex h-1.5 w-1.5">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
                      <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
                      <Coachmark2 steps={steps} currentStep={currentStep} position="bottom" arrowDirection="top" />
                    </span>
                  )}
                </div>

                <RadioGroup className="flex items-center justify-start gap-10" onChange={(e) => {}}>
                  <div className="flex items-center space-x-1">
                    <Radio
                      id="교과활동"
                      name="교과활동"
                      checked={type === '교과'}
                      onChange={() => setType('교과')}
                      className="h-6 w-6 text-brandblue-1 checked:ring-0"
                    />
                    <Label htmlFor="교과활동" children="교과활동" className="cursor-pointer whitespace-pre text-14" />
                  </div>
                  <div className="flex items-center space-x-1">
                    <Radio
                      id="창의적체험활동"
                      name="창의적체험활동"
                      checked={type === '창의적체험활동'}
                      onChange={() => setType('창의적체험활동')}
                      className="h-6 w-6 text-brandblue-1 checked:ring-0"
                    />
                    <Label
                      htmlFor="창의적체험활동"
                      children="창의적 체험활동"
                      className="cursor-pointer whitespace-pre text-14"
                    />
                  </div>
                  {/* <div className="flex items-center space-x-1">
                  <Radio id="행동특성 및 종합의견" name="행동특성 및 종합의견"></Radio>
                  <Label
                    htmlFor="행동특성 및 종합의견"
                    children="행동특성 및 종합의견"
                    className="cursor-pointer whitespace-pre"
                  />
                </div> */}
                </RadioGroup>
              </div>
              <div className="mt-6 flex w-full gap-x-0.5">
                <div className="text-lg font-semibold">선택한 활동</div>
                {currentStep === 3 && coachmarkVisible && (
                  <span className="relative flex h-1.5 w-1.5">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
                    <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
                    <Coachmark2 steps={steps} currentStep={currentStep} position="bottom" arrowDirection="top" />
                  </span>
                )}
              </div>
              <div
                className="mt-2 flex w-max flex-col gap-2 overflow-x-auto
              "
              >
                {selectedActivityV3s.length === 0 ? (
                  <div className="flex w-full justify-center bg-gray-500 px-4 py-2 text-14 text-white">
                    활동을 선택해주세요.
                  </div>
                ) : (
                  selectedActivityV3s.map((activityv3) => (
                    <div key={activityv3.id} className="flex items-center gap-2 rounded-lg py-1.5">
                      <Icon.Close
                        stroke="#FFFFFF"
                        className="h-6 w-6 cursor-pointer rounded-full border border-gray-300 bg-[#466af0] p-1 text-3xl"
                        onClick={() => setCheckedCardIds(checkedCardIds.filter((el) => el !== activityv3.id))}
                      />
                      <div className="whitespace-pre text-14 font-semibold text-[#466af0]">
                        [{activityv3.subject}]&nbsp;
                        {activityv3.title}
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="mt-6 flex flex-grow flex-col gap-2">
                <div className="flex w-full gap-x-0.5">
                  <div className="text-lg font-semibold">성취수준</div>
                  {currentStep === 4 && coachmarkVisible && (
                    <span className="relative flex h-1.5 w-1.5">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
                      <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
                      <Coachmark2 steps={steps} currentStep={currentStep} position="top" arrowDirection="bottom" />
                    </span>
                  )}
                </div>

                <RadioGroup className="flex items-center justify-start gap-16" onChange={() => {}}>
                  <div className="flex items-center space-x-2">
                    <Radio
                      id="상"
                      name="상"
                      checked={achievement === '상'}
                      onChange={() => setAchievement('상')}
                      className="h-6 w-6 text-brandblue-1 checked:ring-0"
                    />
                    <Label htmlFor="상" children="상" className="cursor-pointer whitespace-pre text-14" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Radio
                      id="중"
                      name="중"
                      checked={achievement === '중'}
                      onChange={() => setAchievement('중')}
                      className="h-6 w-6 text-brandblue-1 checked:ring-0"
                    />
                    <Label htmlFor="중" children="중" className="cursor-pointer whitespace-pre text-14" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Radio
                      id="하"
                      name="하"
                      checked={achievement === '하'}
                      onChange={() => setAchievement('하')}
                      className="h-6 w-6 text-brandblue-1 checked:ring-0"
                    />
                    <Label htmlFor="하" children="하" className="cursor-pointer whitespace-pre text-14" />
                  </div>
                </RadioGroup>
              </div>
              <div className="mt-6 flex w-full gap-x-0.5">
                <div className="text-lg font-semibold">성취기준</div>
                {currentStep === 5 && coachmarkVisible && (
                  <span className="relative flex h-1.5 w-1.5">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brandblue-1 opacity-75"></span>
                    <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-brandblue-1"></span>
                    <Coachmark2 steps={steps} currentStep={currentStep} position="top" arrowDirection="bottom" />
                  </span>
                )}
              </div>

              {achievementCriterias == undefined ? (
                <div className="mt-2 flex w-full justify-center rounded bg-gray-500 px-4 py-2 text-white">
                  성취기준이 존재하지 않습니다.
                </div>
              ) : (
                <div className="mt-2 whitespace-pre-line rounded bg-[#F5F5F5] p-2 text-14 text-[#666]">
                  {achievementCriterias?.map((ac) => `[${ac.criteriaId}]\n ${ac.criteria}`).join('\n')}
                </div>
              )}
              {/* <div className="mb-6 mt-6 flex flex-grow flex-col">
              <div className="text-xl font-semibold">과목별 역량 선택</div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                className="mt-2"
                options={[
                  '과학적 문제 해결력',
                  '과학적 탐구 능력',
                  'STS연계 능력',
                  '과학적 의사소통능력',
                  '과학적 참여와 평생 학습 능력',
                ]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div> */}
            </div>
            <div className="min-h-max px-5">
              <button
                className="w-full rounded-lg bg-[#163192] py-2 text-base text-white disabled:bg-gray-500"
                onClick={() =>
                  getGptReport({
                    data: {
                      studentId: studentInfo.id,
                      activityIds: selectedActivityV3s.map((av3) => av3.id),
                      reportType: type,
                      achievement,
                    },
                  })
                }
                disabled={isLoading || !checkedCardIds.length || !type || !studentInfo.id || !achievement}
              >
                {isLoading ? '초안 작성중' : '활동기록부 초안 작성'}
              </button>
            </div>
          </div>
          <div className={`flex w-3/5 ${!content && !isLoading && 'w-2/5'} flex-col overflow-x-visible p-5 pr-6`}>
            {!content && !isLoading && (
              <div className="flex h-full w-full flex-col items-center justify-center gap-10">
                <Icon.AIRobot className="h-60 w-60" />
                <h6 className="text-2xl font-bold text-[#466af0]">활동기록부 초안 작성을 눌러보세요!</h6>
              </div>
            )}
            {isLoading && (
              <div className="flex h-full w-full flex-col items-center justify-center gap-10">
                <Icon.Loader className="h-60 w-60" />
                <h6 className="text-2xl font-bold text-[#466af0]">슈퍼스쿨 AI가 활동기록부 초안을 작성중입니다..</h6>
              </div>
            )}
            {content && (
              <div className=" flex h-full w-full flex-col overflow-y-auto">
                <div className="text-xl font-bold">AI 작성 활동기록부 초안</div>
                <TextareaAutosize
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="mt-2 rounded-md"
                />
                {/* <div className="my-4 h-screen-30 overflow-y-scroll whitespace-pre-line text-14 leading-6">
                  {content}
                </div> */}
                <div className="mt-2 flex items-center gap-2 pb-4">
                  <div>
                    <span className="text-gray-300">공백 제외 </span> {trimmedContent.length}자&nbsp;/&nbsp;{byteLength}
                    Byte
                  </div>
                  <div>
                    <span className="text-gray-300">공백 포함</span> {content.length}자&nbsp;/&nbsp;
                    {new TextEncoder().encode(content).length}Byte
                  </div>
                </div>
                <div className="border-t border-gray-300 pt-4">
                  <div className="font-bold">활동기록부 초안 제목</div>
                  <TextInput
                    placeholder="활동기록부 초안의 제목을 입력해 보세요."
                    className="mt-2 h-10 rounded-lg border border-[#CCCCCC]"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    ref={titleInputRef}
                  />
                </div>
              </div>
            )}

            <div className="flex min-h-max justify-end space-x-2">
              <button
                children="나가기"
                onClick={() => setConfirmOpen(!confirmOpen)}
                className="box-border rounded-md border-2 border-[#163192] px-14 py-2 text-base font-semibold"
              />
              {content && (
                <button
                  children="저장하기"
                  onClick={handleSaveClick}
                  className="box-border rounded-md border-2 border-gray-500 bg-[#163192] px-14 py-2 text-base font-semibold text-white"
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {confirmOpen && (
        <ConfirmDialog
          cancelText="취소"
          confirmText="나가기"
          description="저장되지 않은 내용은 다시 불러올 수 없습니다.<br/> 한번 더 확인해 주세요."
          message="저장되지 않은 내용이 있습니다."
          onCancel={() => setConfirmOpen(!confirmOpen)}
          onConfirm={() => {
            onClose();
            setTitle('');
            setContent('');
            localStorage.setItem('student_record_content', '');
            localStorage.setItem('student_record_type', '');
            setAchievement('');
          }}
        />
      )}
    </div>
  );
};
