import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import { YoutubeLink } from 'src/components/YoutubeLink';
import { BackButton, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';

export function AboutSuperSchoolPage() {
  return (
    <div className="box-border flex w-full items-center justify-center">
      <div className="h-screen-10 w-full max-w-xl">
        <TopNavbar
          title="슈퍼스쿨 미리보기"
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
        />
        <div className="flex flex-col items-center justify-center">
          <Logo className="mt-5 w-28" />
          <div className="font-bold">학교를 쉽고 빠르게, 슈퍼스쿨</div>
        </div>

        <div className="mx-2 mt-5 flex space-x-1 sm:space-x-2">
          <a href="https://superstudy.kr" target="blank" className="flex-grow">
            <Button className="filled-gray-light w-full  overflow-hidden text-ellipsis whitespace-normal rounded-full py-1">
              홈페이지 바로가기
            </Button>
          </a>

          <a
            href={`https://kr.object.gov-ncloudstorage.com/superschool/production/tutorials/aboutsuperschool.pdf`}
            target="_blank"
            rel="noreferrer"
            download
            className="flex-grow"
          >
            <Button className="filled-gray-light w-full overflow-hidden text-ellipsis whitespace-normal rounded-full py-1">
              소개서 다운로드
            </Button>
          </a>
          <a href="https://superstudy.kr/Contact" target="blank" className="flex-grow">
            <Button
              //children="설명회 신청하기"
              className="filled-gray-light w-full overflow-hidden text-ellipsis whitespace-normal rounded-full py-1"
            >
              도입 문의
            </Button>
          </a>
          <a href="https://superstudy.kr/46" target="blank" className="ml-1 mr-6 w-1/2">
            <Button.lg children="설명회 신청하기" className="filled-gray-light w-full rounded-full" />
          </a>
        </div>

        <Section>
          <YoutubeLink ContentTitle="슈퍼스쿨 소개" videoId="DgJcoDfC8tc" />
          <YoutubeLink ContentTitle="슈퍼스쿨 교사 오리엔테이션" videoId="hCmXRwfE6sQ" />
          <YoutubeLink ContentTitle="슈퍼스쿨 교사 사용후기" videoId="Vgce8DlJn00" />
          <YoutubeLink ContentTitle="슈퍼스쿨 학생 사용후기" videoId="sdc2TJfwMnI" />
          <YoutubeLink ContentTitle="실사용 조퇴/외출/학생증" videoId="l8Jr-k7p_oI" />
          <YoutubeLink ContentTitle="출결신고서 사용방법" videoId="xQl6wpaUJNM" />
          <YoutubeLink ContentTitle="체험학습서류 사용방법" videoId="7sTRgYMGyx8" />
          <YoutubeLink ContentTitle="출석부 사용방법" videoId="CbHhd9cE4Xo" />
        </Section>
      </div>
    </div>
  );
}
