import { useParams } from 'react-router-dom';
import SemesterScoreCard from 'src/components/studentCard/SemesterScoreCard';
import SubjectScoreCard from 'src/components/studentCard/SubjectScoreCard';

export const ScoreCardPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="scroll-box mt-4 h-screen-12 overflow-y-auto pb-4 md:h-screen-4">
      <div className="rounded-lg border border-gray-300 px-4 pb-4 pt-4">
        <SemesterScoreCard studentId={id} />
        <SubjectScoreCard studentId={id} />
      </div>
    </div>
  );
};
