import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { queryClient } from './query';

export const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export async function mutator<T>(config: AxiosRequestConfig, options?: AxiosRequestConfig) {
  return api<T>({ ...config, ...options }).then(({ data }) => data);
}

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (['post', 'patch', 'put', 'delete'].includes(response.config.method ?? '')) {
      queryClient.refetchQueries({ active: true });
    }
    return response;
  },
  (error) => {
    const errorResponse = error.response.data;

    tokenstatusCheck(errorResponse);
    return Promise.reject(error);
  },
);

function tokenstatusCheck(errorResponse: any) {
  const checkCode = [
    401,
    '1001200',
    '1001201',
    '1001103', // 2차 인증이 통과되지 않았습니다.
  ];

  const statusCode = errorResponse.code ?? errorResponse.statusCode ?? 400;
  // FIXME: hooks.ts 의 logout 함수 구조 변경이 필요
  if (checkCode.includes(statusCode)) {
    localStorage.setItem('two-factor', 'false');

    if (errorResponse.code !== '1001103') {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }
}

export type ErrorType<Error> = AxiosError<Error>;
