import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

export interface FileUploadProps extends InputHTMLAttributes<HTMLInputElement> {}

export function FileUpload({ children, className, ...props }: FileUploadProps) {
  return (
    <div className={clsx('file-upload', className)}>
      {children}
      <label className="flex h-12 cursor-pointer items-center justify-center space-x-1 text-brand-1">
        <span className="mb-1 text-2xl text-grey-3">+</span>
        <span className="text-sm">첨부파일을 선택해주세요 (다중 업로드 가능)</span>
        <input type="file" multiple className="sr-only" {...props} />
      </label>
    </div>
  );
}
