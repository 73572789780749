import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { ReactComponent as FileItemIcon } from 'src/assets/svg/file-item-icon.svg';
import SvgUser from 'src/assets/svg/user.svg';
import { Constants } from 'src/constants';
import { Chat, ResponseChatAttendeeDto, Role } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { getFileNameFromUrl } from 'src/util/file';
import { getRoleTitle } from 'src/util/permission';
import { isSameMinute } from 'src/util/time';

interface ReceiveMessageProps {
  PreMessageData?: Chat;
  MessageData?: Chat;
  PostMessageData?: Chat;
  AttendeeInfo?: ResponseChatAttendeeDto;
  userRole: 'student' | 'teacher';
}

export function ReceiveMessage({
  PreMessageData,
  MessageData,
  PostMessageData,
  AttendeeInfo,
  userRole,
}: ReceiveMessageProps) {
  const isFirst = PreMessageData?.senderId !== MessageData?.senderId;
  const isLast =
    PostMessageData?.senderId !== MessageData?.senderId ||
    !isSameMinute(MessageData?.createdAt || '', PostMessageData?.createdAt || '');

  const activitiesInContent = useMemo(
    () =>
      !MessageData
        ? []
        : [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/activityv3\/\d+/g)],
    [MessageData],
  );
  const boardsInContent = useMemo(
    () =>
      !MessageData ? [] : [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/board\/\d+/g)],
    [MessageData],
  );
  const newslettersInContent = useMemo(
    () =>
      !MessageData
        ? []
        : [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/newsletter\/\d+/g)],
    [MessageData],
  );
  const noticesInContent = useMemo(
    () =>
      !MessageData
        ? []
        : [...MessageData.content.matchAll(/https:\/\/web.dev.superschool.link\/teacher\/notice\/\d+/g)],
    [MessageData],
  );

  return (
    <div className={`message ${isFirst ? 'mt-2' : 'mt-1'} flex px-3 `}>
      <div className="w-12">
        {isFirst ? (
          <img
            className="mx-auto mr-1 h-12 w-12 rounded-xl"
            src={`${Constants.imageUrl}${AttendeeInfo?.customProfile || AttendeeInfo?.profile}`}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = SvgUser;
              currentTarget.className = 'rounded-full mr-1 w-12 h-12 mx-auto';
            }}
          />
        ) : (
          <div className="mx-auto mr-1 h-1 w-12 items-start" />
        )}
      </div>
      <div className="flex-1 px-2">
        {isFirst &&
          (AttendeeInfo ? (
            AttendeeInfo?.role === Role.USER ? (
              <h4 className="text-sm text-brand-1">
                {AttendeeInfo?.name} {AttendeeInfo?.studentNumber}
              </h4>
            ) : AttendeeInfo?.role === Role.PARENT ? (
              <h4 className="text-sm text-brandblue-1">
                {AttendeeInfo?.name} {'보호자 '}
                {AttendeeInfo?.children &&
                  '(' + AttendeeInfo?.children[0]?.name + ' ' + AttendeeInfo?.children[0]?.studentNumber + ')'}
              </h4>
            ) : (
              <h4 className="text-sm text-gray-900">
                {AttendeeInfo?.name} {AttendeeInfo?.klass}{' '}
                {getRoleTitle(AttendeeInfo?.role || Role.TEACHER, AttendeeInfo?.headNumber)}
                {AttendeeInfo?.position} {AttendeeInfo?.department}
              </h4>
            )
          ) : (
            <h4 className="text-sm text-red-500">퇴장한 사용자입니다.</h4>
          ))}

        <div className="flex items-end">
          <div className="mr-2 rounded-md bg-white p-1 px-6 text-gray-700">
            <p className="feedback_space whitespace-pre-wrap break-all text-sm">
              <Linkify>{MessageData?.content}</Linkify>
            </p>
          </div>
          <div className="w-20 pl-2">
            {isLast && (
              <small className="text-gray-500">
                {MessageData?.createdAt && DateUtil.formatDate(MessageData?.createdAt, DateFormat['HH:mm'])}
              </small>
            )}
          </div>
        </div>
        {!!MessageData?.images?.length && (
          <div className="grid w-full grid-flow-row grid-cols-6 gap-2 pb-2">
            {MessageData.images.map((image: string, i: number) => (
              <div key={i} className="w-full">
                <div className="aspect-square rounded border border-neutral-200">
                  <LazyLoadImage
                    src={`${Constants.imageUrl}${image}`}
                    alt=""
                    loading="lazy"
                    className="object-fit h-full w-full rounded"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {!!MessageData?.files?.length && (
          <div className="flex flex-col gap-1 pb-2">
            {MessageData.files.map((fileUrl: string, index) => (
              <div key={index} className="flex h-8 w-max items-center space-x-2 rounded bg-stone-50 px-3 py-1">
                <FileItemIcon />
                <a
                  className="ml-2 text-xs text-neutral-500"
                  href={`${Constants.imageUrl}${fileUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  download={getFileNameFromUrl(fileUrl)}
                >
                  {getFileNameFromUrl(fileUrl)}
                </a>
              </div>
            ))}
          </div>
        )}
        {!!activitiesInContent.length && (
          <div className="mt-1 flex w-min max-w-2/3 flex-col items-start space-y-1">
            {activitiesInContent.map((el) => (
              <a
                href={el[0]}
                className="block w-full cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
              >
                {`https://web.dev.superschool.link/${userRole}/activityv3/` + el[0].split('/').pop()}
              </a>
            ))}
          </div>
        )}
        {!!boardsInContent.length && (
          <div className="mt-1 flex w-min max-w-2/3 flex-col items-start space-y-1">
            {boardsInContent.map((el) => (
              <a
                href={el[0]}
                className="block w-full cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
              >
                {`https://web.dev.superschool.link/${userRole}/board/` + el[0].split('/').pop()}
              </a>
            ))}
          </div>
        )}
        {!!newslettersInContent.length && (
          <div className="mt-1 flex w-min max-w-2/3 flex-col items-start space-y-1">
            {newslettersInContent.map((el) => (
              <a
                href={el[0]}
                className="block w-full cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
              >
                {`https://web.dev.superschool.link/${userRole}/newsletter/` + el[0].split('/').pop()}
              </a>
            ))}
          </div>
        )}
        {!!noticesInContent.length && (
          <div className="mt-1 flex w-min max-w-2/3 flex-col items-start space-y-1">
            {noticesInContent.map((el) => (
              <a
                href={el[0]}
                className="block w-full cursor-pointer overflow-hidden whitespace-pre-line break-words rounded-md bg-white px-4 py-2 text-sm text-gray-500"
              >
                {`https://web.dev.superschool.link/${userRole}/notice/` + el[0].split('/').pop()}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
