import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Label, Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { useCodeByCategoryName } from 'src/container/category';
import {
  studentManagementCreateStudent,
  studentManagementUpdateStudent,
  useStudentManagementGetStudentInfo,
} from 'src/generated/endpoint';
import { Category, RequestCreateStudentDto, RequestModifyStudentDto } from 'src/generated/model';
import { form } from 'src/lib/form';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { getErrorMsg } from 'src/util/status';
import { AdminContext } from '../AdminMainPage';

export function StudentEditPage() {
  const { goBack } = useHistory();
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);
  const { year } = useContext(AdminContext);

  const setToastMsg = useSetRecoilState(toastState);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<RequestModifyStudentDto & RequestCreateStudentDto>();

  const { categoryData: studentStates } = useCodeByCategoryName(Category.studentstatus);
  const { data: studentInfo } = useStudentManagementGetStudentInfo(id, { year }, { query: { enabled: !!id } });

  useEffect(() => studentInfo && reset(studentInfo.studentData), [studentInfo]);

  async function save(params: any) {
    id
      ? await studentManagementUpdateStudent(id, params)
          .then((result) => {
            setToastMsg(`${params.name} 님 수정완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          })
      : await studentManagementCreateStudent({ ...params, year })
          .then((result) => {
            setToastMsg(`${params.name} 님 추가완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          });
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">학생 {id ? '수정' : '추가'}</Admin.H2>

      <Label.col>
        <Label.Text children="*이름" />
        <TextInput placeholder="이름" {...register('name', form.length(1, 100))} />
        <Label.Error children={errors.name?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children="*이메일" />
        <TextInput placeholder="이메일" {...register('email', form.length(1, 100))} />
        <Label.Error children={errors.email?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children="전화번호" />
        <TextInput placeholder="전화번호" {...register('phone', form.length(0, 100))} />
        <Label.Error children={errors.phone?.message} />
      </Label.col>
      {!id && (
        <Label.col>
          <Label.Text children="*학년" />
          <TextInput placeholder="학년" type="number" {...register('grade', form.minmax(1, 3))} />
          <Label.Error children={errors.grade?.message} />
        </Label.col>
      )}
      {!id && (
        <Label.col>
          <Label.Text children="*반" />
          <TextInput placeholder="반" type="number" {...register('klass', form.minmax(1, 100))} />
          <Label.Error children={errors.klass?.message} />
        </Label.col>
      )}
      {!id && (
        <Label.col>
          <Label.Text children="*번호" />
          <TextInput placeholder="번호" type="number" {...register('studentNumber', form.minmax(1, 100))} />
          <Label.Error children={errors.studentNumber?.message} />
        </Label.col>
      )}
      {!!id && studentStates && (
        <Label.col>
          <Label.Text children="학적상태" />
          <Select.lg
            {...register('expiredReason', {
              onChange: (e) => {
                const state = studentStates.find((s) => s.name === e.target.value);
                setValue('expired', state?.etc1 === 'true');
                setValue('expiredReason', e.target.value);
              },
            })}
            className="h-13"
          >
            <option value="">학적상태</option>
            {studentStates?.map((state) => (
              <option key={state.id} value={state.name}>
                {state.name}
              </option>
            ))}
          </Select.lg>
        </Label.col>
      )}
      <Label.col>
        <Label.Text children="학부모이름" />
        <TextInput placeholder="학부모이름" {...register('nokName', form.length(0, 100))} />
        <Label.Error children={errors.nokName?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children="학부모전화번호" />
        <TextInput placeholder="학부모전화번호" {...register('nokPhone', form.length(0, 100))} />
        <Label.Error children={errors.nokPhone?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children="회원바코드" />
        <TextInput placeholder="회원바코드" {...register('barcode', form.length(0, 100))} />
        <Label.Error children={errors.barcode?.message} />
      </Label.col>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <Link children="취소" to={id ? `../${id}` : Routes.admin.student.index} className="button-lg outlined-gray" />
        <Button.lg children="저장" disabled={!isValid} onClick={handleSubmit(save)} className="filled-gray" />
      </div>
    </Admin.Section>
  );
}
