import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { BackButton, Blank, List, TopNavbar } from 'src/components/common';
import Coulselingv3Card from 'src/components/studentCard/Coulselingv3Card';
import { StudyInfoCard } from 'src/components/studentCard/StudyInfoCard';
import { useTeacherStudentCard } from 'src/container/teacher-studentcard';
import { meState } from 'src/store';
import { ActivityV3Page } from './ActivityV3Page';
import { AllCardPage } from './AllCardPage';
import { ScoreCardPage } from './ScoreCardPage';

export function StudentCardDetailPage() {
  const meRecoil = useRecoilValue(meState);

  const { id, cardType, groupId } = useParams<{ id: string; cardType: string; groupId: string }>();
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const { studentInfo, isForbidden, isLoading } = useTeacherStudentCard(Number(id));
  const searchParams = new URLSearchParams(search);

  if (isLoading) return <Blank />;

  const handleReplace = (path: string) => {
    replace({
      pathname: path,
      search: searchParams.toString(),
    });
  };

  return (
    <>
      <div className="block md:hidden">
        <TopNavbar title="학생정보" left={<BackButton />} />
      </div>
      <div className="col-span-8 h-screen-4 md:h-screen">
        {isForbidden ? (
          <div className="relative h-full bg-gray-50">
            <div className="bg-gray relative h-full w-auto p-4">
              <List className="flex flex-row md:m-0">
                <button
                  className={`rounded-md px-4 py-1 ${
                    pathname === `/teacher/studentcard/${groupId}/${id}/default`
                      ? 'bg-orange-400 font-bold text-white'
                      : 'hover:font-bold'
                  }`}
                  onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/default`)}
                >
                  종합카드
                </button>
                <button
                  className={`rounded-md px-1 py-1 md:h-10 md:w-24 md:px-2 md:text-xl ${
                    cardType === 'activityv3' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                  }`}
                  onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/activityv3`)}
                >
                  활동카드
                </button>
              </List>
              <div>
                <Switch>
                  <Route
                    exact
                    path={`/teacher/studentcard/:groupId/:id/default`}
                    component={() => <StudyInfoCard studentId={Number(id)} isCard={true} isForbidden={isForbidden} />}
                  />
                  <Route path={`/teacher/studentcard/:groupId/:id/activityv3`} component={ActivityV3Page} />
                </Switch>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="relative h-full bg-gray-50">
              <div className="bg-gray relative h-full w-auto p-4">
                <List className="flex flex-row text-sm md:m-0">
                  <button
                    className={`rounded-md px-1 py-1 ${
                      cardType === 'default' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                    } md:h-10 md:w-24 md:px-2 md:text-xl`}
                    onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/default`)}
                  >
                    기본카드
                  </button>
                  {meRecoil?.schoolId === 2 && (
                    <button
                      className={`rounded-md px-1 py-1 md:h-10 md:w-24 md:px-2 md:text-xl ${
                        cardType === 'score' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                      }`}
                      onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/score`)}
                    >
                      성적카드
                    </button>
                  )}
                  <button
                    className={`rounded-md px-1 py-1 md:h-10 md:w-24 md:px-2 md:text-xl ${
                      cardType === 'activityv3' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                    }`}
                    onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/activityv3`)}
                  >
                    활동카드
                  </button>
                  <button
                    className={`rounded-md px-1 py-1 md:h-10 md:w-24 md:px-2 md:text-xl ${
                      cardType === 'all' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                    }`}
                    onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/all`)}
                  >
                    종합카드
                  </button>

                  <button
                    className={`rounded-md px-1 py-1 md:h-10 md:w-24 md:px-2 md:text-xl ${
                      cardType === 'coulseling' ? 'bg-orange-400 text-white' : 'hover:font-bold'
                    }`}
                    onClick={() => handleReplace(`/teacher/studentcard/${groupId}/${id}/coulseling`)}
                  >
                    상담카드
                  </button>
                </List>
                <div>
                  <Switch>
                    <Route path={`/teacher/studentcard/:groupId/:id/score`} component={ScoreCardPage} />

                    <Route path={`/teacher/studentcard/:groupId/:id/activityv3`} component={ActivityV3Page} />
                    <Route
                      path={`/teacher/studentcard/:groupId/:id/all`}
                      component={() => (
                        <StudyInfoCard studentId={studentInfo?.student.id} isCard={true} isForbidden={isForbidden} />
                      )}
                    />
                    <Route
                      path={`/teacher/studentcard/:groupId/:id/coulseling`}
                      component={() => <Coulselingv3Card studentId={Number(id)} />}
                    />
                    <Route exact path={`/teacher/studentcard/:groupId/:id/default`} component={AllCardPage} />
                  </Switch>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
