import { Checkbox } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Label, Radio } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import {
  fieldtripsReCalculateFieldtripDaysWithUser,
  schoolManagementLoadMealData,
  schoolManagementLoadYearHolidayData,
  schoolManagementModifySchoolInfo,
  useSchoolManagementGetSchoolInfo,
} from 'src/generated/endpoint';
import { RequestUpdateSchoolInfoDto, UploadFileTypeEnum } from 'src/generated/model';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { useSignedUrl } from 'src/lib/query';
import { toastState } from 'src/store';

interface PrivacyManager {
  name: string;
  position: string;
  contact: string;
}

export function SchoolPage() {
  const [editPrivacyManager, setEditPrivacyManager] = useState(false);
  const [editFieldtripDays, setEditFieldtripDays] = useState(false);

  const { handleSubmit, register, reset, watch, setValue } = useForm<
    RequestUpdateSchoolInfoDto & { privacyManager: PrivacyManager }
  >();

  const fieldtripType = watch('fieldtripType', []) || [];
  const enhancedSecurity = watch('enhancedSecurity', false);

  const { handleUploadFile } = useFileUpload();

  const { data: school, isLoading, refetch } = useSchoolManagementGetSchoolInfo();
  const { data: mark } = useSignedUrl(school?.mark);
  const { data: stamp } = useSignedUrl(school?.stamp);

  const [name, position, contact] = (school?.privacyPolicyManager || '||').split('|');
  const privacyManager = { name, position, contact };

  const setToastMsg = useSetRecoilState(toastState);

  useEffect(() => school && reset({ ...school, privacyManager }), [school, editPrivacyManager, editFieldtripDays]);

  const handleCheckboxChange = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setValue('fieldtripType', [...fieldtripType, value]);
    } else {
      setValue(
        'fieldtripType',
        fieldtripType.filter((item) => item !== value),
      );
    }
  };

  async function loadHoliday() {
    if (!school) return;
    if (!confirm(`공휴일 정보를 불러올까요?`)) return;
    const date = new Date();
    //await schoolManagementLoadHolidayData({ year: date.getFullYear(), month: date.getMonth() + 1 });
    await schoolManagementLoadYearHolidayData({ year: date.getFullYear() });
    refetch();
    setToastMsg(date.getFullYear() + '년 공휴일 정보를 불러왔습니다. 캘린더에서 확인하세요.');
  }

  async function loadMeal() {
    if (!school) return;
    if (!confirm(`급식 정보를 불러올까요?`)) return;
    await schoolManagementLoadMealData();
    refetch();
    setToastMsg('급식 정보를 불러왔습니다. 급식표에서 확인하세요.');
  }

  async function uploadMark(e: ChangeEvent<HTMLInputElement>) {
    const [mark] = await handleUploadFile(UploadFileTypeEnum.schoolmark, [...(e.target.files ?? [])]);
    await schoolManagementModifySchoolInfo({ mark });
  }

  async function uploadStamp(e: ChangeEvent<HTMLInputElement>) {
    const [stamp] = await handleUploadFile(UploadFileTypeEnum.schoolstamp, [...(e.target.files ?? [])]);
    await schoolManagementModifySchoolInfo({ stamp });
  }

  const updatePrivacyManager = handleSubmit(async ({ privacyManager }) => {
    setEditPrivacyManager(false);
    const privacyPolicyManager = `${privacyManager.name}|${privacyManager.position}|${privacyManager.contact}`;
    await schoolManagementModifySchoolInfo({ privacyPolicyManager });
  });

  const updateFieldtripDays = handleSubmit(
    async ({ fieldtripDays, fieldtripDueDays, fieldtripResultDueDays, studentSafeText, fieldtripType }) => {
      setEditFieldtripDays(false);
      await schoolManagementModifySchoolInfo({
        fieldtripDays,
        fieldtripDueDays,
        fieldtripResultDueDays,
        studentSafeText,
        fieldtripType,
      });
    },
  );

  const updateEnhancedSecurity = handleSubmit(async ({ enhancedSecurity }) => {
    await schoolManagementModifySchoolInfo({
      enhancedSecurity,
    });
  });

  async function recalculateFieldtripDays() {
    if (!school) return;
    if (!confirm(`체험학습 사용일수를 다시 계산할까요?`)) return;
    await fieldtripsReCalculateFieldtripDaysWithUser(school.id);
    refetch();
    setToastMsg('전체 학생의 체험학습 사용일수를 다시 계산합니다.');
  }

  if (isLoading) return <>Loading...</>;
  if (!school) return <>Not Found</>;
  return (
    <>
      <Admin.Section>
        <div className="flex items-center justify-between">
          <Admin.H2>학교 기본정보</Admin.H2>
          <div className="flex gap-2">
            <Button.sm children="공휴일 불러오기" onClick={loadHoliday} className="outlined-gray" />
            <Button.sm children="급식정보 불러오기" onClick={loadMeal} className="outlined-gray" />
          </div>
        </div>
        <Admin.Card className="grid grid-cols-3 divide-x">
          <div className="grid divide-y">
            <Admin.Cell>
              <Label.Text children="학교명" />
              <p className="mt-2">{school.name}</p>
            </Admin.Cell>
            <Admin.Cell>
              <Label.Text children="교육청 코드 / 학교 코드" />
              <p className="mt-2">
                {school.ooeCode} / {school.schoolCode}
              </p>
            </Admin.Cell>
          </div>
          <Admin.Cell>
            <Label.Text children="학교 마크" />
            <div className="mt-2 flex h-40 items-center justify-center">
              {school.mark ? (
                <img src={mark} className="aspect-square h-full object-cover" />
              ) : (
                <p className="text-gray-500">등록된 이미지가 없습니다.</p>
              )}
            </div>
            {school.mark ? (
              <div className="mt-4 grid grid-cols-2 gap-2">
                <Button
                  children="삭제"
                  onClick={() =>
                    confirm('학교 마크 이미지를 삭제하시겠습니까?') && schoolManagementModifySchoolInfo({ mark: '' })
                  }
                  className="filled-red-light"
                />
                <Label className="button outlined-gray cursor-pointer">
                  <p>이미지 수정</p>
                  <input type="file" accept=".png, .jpeg, .jpg" onChange={uploadMark} className="sr-only" />
                </Label>
              </div>
            ) : (
              <Label className="button filled-gray-light mt-4 cursor-pointer">
                <p>이미지 업로드</p>
                <input type="file" accept=".png, .jpeg, .jpg" onChange={uploadMark} className="sr-only" />
              </Label>
            )}
          </Admin.Cell>
          <Admin.Cell>
            <Label.Text children="학교 직인" />
            <div className="mt-2 flex h-40 items-center justify-center">
              {school.stamp ? (
                <img src={stamp} className="aspect-square h-full object-cover" />
              ) : (
                <p className="text-gray-500">등록된 이미지가 없습니다.</p>
              )}
            </div>
            {school.stamp ? (
              <div className="mt-4 grid grid-cols-2 gap-2">
                <Button
                  children="삭제"
                  onClick={() =>
                    confirm('학교 직인 이미지를 삭제하시겠습니까?') && schoolManagementModifySchoolInfo({ stamp: '' })
                  }
                  className="filled-red-light"
                />
                <Label className="button outlined-gray cursor-pointer">
                  <p>이미지 수정</p>
                  <input type="file" accept=".png, .jpeg, .jpg" onChange={uploadStamp} className="sr-only" />
                </Label>
              </div>
            ) : (
              <Label className="button filled-gray-light mt-4 cursor-pointer">
                <p>이미지 업로드</p>
                <input type="file" accept=".png, .jpeg, .jpg" onChange={uploadStamp} className="sr-only" />
              </Label>
            )}
          </Admin.Cell>
        </Admin.Card>
      </Admin.Section>

      <Admin.Section>
        <div className="flex items-center justify-between">
          <Admin.H2>개인정보 취급자 정보</Admin.H2>
          <div className="flex gap-2">
            {editPrivacyManager ? (
              <>
                <Button.sm children="취소" onClick={() => setEditPrivacyManager(false)} className="outlined-gray" />
                <Button.sm children="저장" onClick={updatePrivacyManager} className="filled-gray-dark" />
              </>
            ) : (
              <Button.sm
                children={school.privacyPolicyManager ? '수정' : '+등록'}
                onClick={() => setEditPrivacyManager(true)}
                className={school.privacyPolicyManager ? 'outlined-gray' : 'filled-gray-dark'}
              />
            )}
          </div>
        </div>
        {school.privacyPolicyManager || editPrivacyManager ? (
          <Admin.Card className="grid grid-cols-3 divide-x">
            <Admin.Cell>
              <Label.col>
                <Label.Text children="이름" />
                {editPrivacyManager ? (
                  <TextInput {...register('privacyManager.name', { minLength: 1 })} />
                ) : (
                  <p>{privacyManager.name}</p>
                )}
              </Label.col>
            </Admin.Cell>
            <Admin.Cell>
              <Label.col>
                <Label.Text children="직급" />
                {editPrivacyManager ? (
                  <TextInput {...register('privacyManager.position', { minLength: 1 })} />
                ) : (
                  <p>{privacyManager.position}</p>
                )}
              </Label.col>
            </Admin.Cell>
            <Admin.Cell>
              <Label.col>
                <Label.Text children="연락처" />
                {editPrivacyManager ? (
                  <TextInput {...register('privacyManager.contact', { minLength: 1 })} />
                ) : (
                  <p>{privacyManager.contact}</p>
                )}
              </Label.col>
            </Admin.Cell>
          </Admin.Card>
        ) : (
          <Admin.Card className="flex items-center justify-center py-10">
            <p>등록된 정보가 없습니다. ‘+등록’ 버튼을 눌러 정보를 등록해 주세요.</p>
          </Admin.Card>
        )}
      </Admin.Section>
      <Admin.Section>
        <div className="flex items-center justify-between">
          <Admin.H2>보안 설정</Admin.H2>
        </div>
        <div className="grid divide-y">
          <Admin.Cell>
            <Label.row className="h-9 rounded-md px-1">
              <Radio
                checked={enhancedSecurity}
                onChange={(e) => {
                  if (confirm('향상된 보안 모드를 사용하시겠습니까?')) {
                    setValue('enhancedSecurity', e.target.checked);
                    updateEnhancedSecurity();
                  }
                }}
                className="filled-red-light mr-1 rounded-full"
              />
              <p className="">향상된 보안 모드</p>
            </Label.row>
            <div className="ml-10">
              <Label.Text children="향상된 보안 모드를 사용하여 안전하게 개인정보를 보호합니다." />
              <Label.Text children="로그인시 ID/PW 인증 후, 개인 휴대전화로 전송된 인증번호를 입력해야 로그인할 수 있습니다." />
              <Label.Text children="모든 목록의 개인정보는 마스킹되어 표시됩니다. " />
            </div>
          </Admin.Cell>
          <Admin.Cell>
            <Label.row className="h-9 rounded-md px-1">
              <Radio
                checked={!enhancedSecurity}
                onChange={(e) => {
                  if (confirm('편의성 우선 모드를 사용하시겠습니까?')) {
                    setValue('enhancedSecurity', !e.target.checked);
                    updateEnhancedSecurity();
                  }
                }}
                className="filled-red-light mr-1 rounded-full"
              />
              <p className="">편의성 우선 모드</p>
            </Label.row>
            <div className="ml-10">
              <Label.Text children="ID/PW 인증으로 로그인합니다. 자동로그인이 가능하며 사용자가 직접 로그아웃 버튼을 눌러 로그아웃 할 수 있습니다." />
            </div>
          </Admin.Cell>
        </div>
      </Admin.Section>

      <Admin.Section>
        <div className="flex items-center justify-between">
          <Admin.H2>체험학습</Admin.H2>
          <div className="flex gap-2">
            {editFieldtripDays ? (
              <>
                <Button.sm children="취소" onClick={() => setEditFieldtripDays(false)} className="outlined-gray" />
                <Button.sm children="저장" onClick={updateFieldtripDays} className="filled-gray-dark" />
              </>
            ) : (
              <>
                <Button.sm
                  children="학생별 사용일수 재계산"
                  onClick={recalculateFieldtripDays}
                  className="outlined-gray"
                />
                <Button.sm children="수정" onClick={() => setEditFieldtripDays(true)} className="outlined-gray" />
              </>
            )}
          </div>
        </div>
        <Admin.Card>
          <div className="grid grid-cols-2 divide-x">
            <Admin.Cell className="flex-row items-center justify-between">
              <p>체험학습 종류</p>
              {editFieldtripDays ? (
                <div>
                  <Checkbox
                    checked={fieldtripType?.includes('suburb')}
                    onChange={(e) => handleCheckboxChange('suburb', e.target.checked)}
                  />
                  <span>교외체험학습</span>

                  <Checkbox
                    checked={fieldtripType?.includes('home')}
                    onChange={(e) => handleCheckboxChange('home', e.target.checked)}
                  />
                  <span>가정학습</span>
                </div>
              ) : (
                <p className="font-medium">
                  {fieldtripType?.includes('suburb') && '교외체험학습 | '}
                  {fieldtripType?.includes('home') && '가정학습 | '}
                </p>
              )}
            </Admin.Cell>
          </div>
          <div className="grid grid-cols-3 divide-x">
            <Admin.Cell className="flex-row items-center justify-between">
              <p>연중 사용일수</p>
              {editFieldtripDays ? (
                <TextInput {...register('fieldtripDays', { valueAsNumber: true })} className="w-16" />
              ) : (
                <p className="font-medium">{school.fieldtripDays}</p>
              )}
            </Admin.Cell>
            <Admin.Cell className="flex-row items-center justify-between">
              <p>신청서 제출기한</p>
              {editFieldtripDays ? (
                <TextInput {...register('fieldtripDueDays', { valueAsNumber: true })} className="w-16" />
              ) : (
                <p className="font-medium">{school.fieldtripDueDays}</p>
              )}
            </Admin.Cell>
            <Admin.Cell className="flex-row items-center justify-between">
              <p>완료보고서 제출기한</p>
              {editFieldtripDays ? (
                <TextInput {...register('fieldtripResultDueDays', { valueAsNumber: true })} className="w-16" />
              ) : (
                <p className="font-medium">{school.fieldtripResultDueDays}</p>
              )}
            </Admin.Cell>
          </div>
          <div className="grid grid-cols-1 divide-x">
            <Admin.Cell>
              <Label.col>
                <Label.Text children="학생안전문구" />
                {editFieldtripDays ? <TextInput {...register('studentSafeText')} /> : <p>{school.studentSafeText}</p>}
              </Label.col>
            </Admin.Cell>
          </div>
        </Admin.Card>
      </Admin.Section>
    </>
  );
}
