import { useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ErrorBlank, SuperModal } from 'src/components';
import { Divider, Section } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { TeacharAllGroup } from 'src/container/teacher-group-all';
import { useTeacherGroupDetail } from 'src/container/teacher-group-detail';
import { meState } from 'src/store';
import { makeStudNum5 } from 'src/util/status';
import { GroupAddPage } from './GroupAddPage';

interface GroupDetailPageProps {
  selectedGroup?: TeacharAllGroup;
}

export function GroupDetailPage({ selectedGroup }: GroupDetailPageProps) {
  const { id } = useParams<{ id: string }>();
  const me = useRecoilValue(meState);
  const { group, studentGroups, teacherGroups, errorMessage, handleGroupDelete } = useTeacherGroupDetail(+id);
  const [updateState, setUpdateState] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  if (errorMessage) return <ErrorBlank />;

  if (updateState) {
    return (
      <GroupAddPage
        groupData={group}
        //groupStudentsData={studentGroups.map((el) => el.user)}
        onSubmit={() => {
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <div className="h-screen overflow-auto bg-gray-50 p-4">
      <SuperModal modalOpen={isDeleteModalOpen} setModalClose={() => setDeleteModalOpen(false)} width="w-max">
        {group && group.activityCount && Number(group.activityCount) > 0 ? (
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-600">
              해당 그룹에 연관된 활동이 <span className="text-red-500">{group.activityCount}</span>건 존재하여 삭제가
              불가능합니다. <br />
              해당 그룹에 연관된 활동을 삭제해 주시기 바랍니다.
            </div>
          </Section>
        ) : (
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              이 그룹을 삭제하면 그룹에 연관된 데이터(활동,출석,시간표 등)도 같이 삭제됩니다. <br />
              정말 선택된 그룹을 삭제하시겠습니까?
            </div>
            <Button.xl children="삭제하기" onClick={() => handleGroupDelete()} className="filled-primary" />
          </Section>
        )}
      </SuperModal>
      <div className="flex items-center justify-between px-3 pt-6">
        <div className="text-xl font-semibold">{selectedGroup?.name}</div>
        {selectedGroup?.origin === 'USER' ? (
          <div className=" mb-1 flex cursor-pointer space-x-2 ">
            <Button.sm children="수정" onClick={() => setUpdateState(true)} className="filled-yellow" />
            <Button.sm children="삭제" onClick={() => setDeleteModalOpen(true)} className="filled-red" />
          </div>
        ) : (
          <div className="text-gray-700">{selectedGroup?.originKor}</div>
        )}
      </div>
      <Divider className="bg-black" />

      <div className="my-6">
        <label className="text-sm text-gray-800">담당선생님</label>
        {teacherGroups && teacherGroups.length > 0 ? (
          teacherGroups.map((teacher) => (
            <div key={teacher.id} className="m-1 w-full rounded-lg border-2 border-grey-6 px-3 py-3">
              <div className="">담당 : {teacher.user.name} 선생님</div>
              <div className="">과목 : {teacher.subject || selectedGroup?.subject}</div>
              <div className="">교실 : {teacher.room || selectedGroup?.room}</div>
            </div>
          ))
        ) : (
          <div key={selectedGroup?.id} className="m-1 w-full rounded-lg border-2 border-grey-6 px-3 py-3">
            <div className="">담당 : {me?.name} 선생님</div>
            <div className="">과목 : {selectedGroup?.subject}</div>
            <div className="">교실 : {selectedGroup?.room}</div>
          </div>
        )}
      </div>

      <div className="my-6">
        <label className="text-sm text-gray-800">소속 학생 ({studentGroups.length}명)</label>
        <div className="grid w-full grid-flow-row grid-cols-2 gap-2 pr-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {studentGroups.map((student) => (
            <div key={student.id} className="w-full rounded-lg border-2 border-grey-6 px-3 py-1">
              <div className="w-full">{makeStudNum5(student.klass + student.studentNumber.toString())}</div>
              <div className="w-full">{student.userName}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
