import { range } from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { CoachMark } from 'react-coach-mark';
import { useSetRecoilState } from 'recoil';
import { Label, Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { CoachPosition, Guide, useCoachMark } from 'src/components/common/CoachMark';
import { TextInput } from 'src/components/common/TextInput';
import { useCodeByCategoryName } from 'src/container/category';
import {
  approvalLineCreateApprovalLines,
  approvalLineUpdateFieldTripApprovalLines,
  approvalLineUpdateOutingApprovalLines,
  approvalLineUpdateabsentApprovalLines,
  useAdminCommonFindAllKlassBySchool,
  useAdminCommonSearchTeachers,
  useApprovalLineGetApprovalLineByType2,
  useSchoolManagementGetSchoolInfo,
} from 'src/generated/endpoint';
import { ApprovalLine, Category, ResponseApprovalLineDto, ResponseTeacherInfoDto, Role } from 'src/generated/model';
import { toastState } from 'src/store';
import { AdminContext } from '../AdminMainPage';

const types = [
  { name: '확인증', subTypes: [{ name: '기본' }] },
  { name: '조퇴증', subTypes: [{ name: '기본' }] },
  { name: '외출증', subTypes: [{ name: '기본' }] },
  {
    name: '체험',
    subTypes: [
      { name: '기본' },
      // { name: '가정신청' },
      // { name: '교외신청' },
      // { name: '해외신청' },
      // { name: '가정결과' },
      // { name: '교외결과' },
      // { name: '해외결과' },
    ],
  },
  { name: '출결신고서', subTypes: [{ name: '기본' }] },
];

const steps = range(1, 6) as (1 | 2 | 3 | 4 | 5)[];

export function ApprovalLinePage() {
  const { year } = useContext(AdminContext);
  const [type1, setType1] = useState(types[0].name);
  const [type2, setType2] = useState(types[0].subTypes[0].name);
  const [modified, setModified] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [enabledStep, setEnabledStep] = useState(1);
  const [titles, setTitles] = useState(new Map<number, string>());
  const [tree, setTree] = useState(new Map<number, Map<number, ResponseApprovalLineDto>>());

  const { categoryData } = useCodeByCategoryName(Category.outingtype);
  const { categoryData: AbsentData } = useCodeByCategoryName(Category.absenttype);
  const { data: school } = useSchoolManagementGetSchoolInfo();
  const { data: klasses } = useAdminCommonFindAllKlassBySchool({ year });
  const { data: approvalLines } = useApprovalLineGetApprovalLineByType2({ type1, type2 });
  const { data: allTeachers } = useAdminCommonSearchTeachers({ year });

  const setToastMsg = useSetRecoilState(toastState);

  useEffect(() => {
    if (!categoryData) return;
    types[0].subTypes = [
      { name: '기본' },
      ...categoryData.sort((a, b) => a.name.localeCompare(b.name)).map(({ name }) => ({ name })),
    ];
  }, [categoryData]);

  useEffect(() => {
    if (!AbsentData) return;
    types[4].subTypes = [
      { name: '기본' },
      ...AbsentData.sort((a, b) => a.name.localeCompare(b.name)).map(({ name }) => ({ name })),
    ];
  }, [AbsentData]);

  useEffect(() => {
    if (!school) return;

    if (school.fieldtripType.length === 1) {
      if (school.fieldtripType.includes('suburb')) {
        types[3].subTypes = [
          { name: '기본' },
          { name: '교외신청' },
          { name: '해외신청' },
          { name: '교외결과' },
          { name: '해외결과' },
        ];
      } else if (school.fieldtripType.includes('home')) {
        types[3].subTypes = [{ name: '기본' }, { name: '가정신청' }, { name: '가정결과' }];
      }
    } else {
      types[3].subTypes = [
        { name: '기본' },
        { name: '가정신청' },
        { name: '교외신청' },
        { name: '해외신청' },
        { name: '가정결과' },
        { name: '교외결과' },
        { name: '해외결과' },
      ];
    }
  }, [school]);

  // Initialization
  useEffect(() => {
    if (!school || !klasses || !approvalLines) return;
    const newTitles = new Map(steps.map((step) => [step, approvalLines[0]?.[`approver${step}Title`] ?? '']));
    setEnabledStep([...newTitles.values()].filter((v) => !!v).length);
    setTitles(newTitles);
    setTree(
      klasses?.reduce((prev, k) => {
        const approvalLine = approvalLines.find((al) => al.groupId === k.id) ?? {
          schoolId: school.id,
          groupId: k.id,
          type1,
          type2,
        };
        return prev.set(k.grade, (prev.get(k.grade) ?? new Map()).set(k.klass, approvalLine));
      }, new Map<number, Map<number, ResponseApprovalLineDto>>()),
    );
    setModified(false);
  }, [type1, type2, toggle, school, klasses, approvalLines]);

  const idMap = allTeachers?.items.reduce((map: any, obj: any) => {
    map[obj.id] = obj;
    return map;
  }, {});

  // idMap의 값들을 배열로 변환합니다.
  const uniqueTeachers: ResponseTeacherInfoDto[] = idMap && Object.values(idMap);

  const teachers = uniqueTeachers?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  function save() {
    if (!school) return;
    setToastMsg('결재라인을 저장 중입니다.');
    const payloads = [...tree].reduce((prev, [, als]) => {
      const payloads = [...als].map(([, al]) => {
        return {
          id: al.id,
          schoolId: al.schoolId,
          groupId: al.groupId,
          type1: type1,
          type2: type2,
          approver1Id: 1 <= enabledStep ? al.approver1Id : 0,
          approver2Id: 2 <= enabledStep ? al.approver2Id : 0,
          approver3Id: 3 <= enabledStep ? al.approver3Id : 0,
          approver4Id: 4 <= enabledStep ? al.approver4Id : 0,
          approver5Id: 5 <= enabledStep ? al.approver5Id : 0,
          approver1Title: 1 <= enabledStep ? titles.get(1) : '',
          approver2Title: 2 <= enabledStep ? titles.get(2) : '',
          approver3Title: 3 <= enabledStep ? titles.get(3) : '',
          approver4Title: 4 <= enabledStep ? titles.get(4) : '',
          approver5Title: 5 <= enabledStep ? titles.get(5) : '',
        } as ApprovalLine;
      });
      return [...prev, ...payloads];
    }, [] as ApprovalLine[]);
    approvalLineCreateApprovalLines(payloads);
    setToastMsg('결재라인을 저장했습니다.');
  }

  function reSetApprovalLine() {
    if (['확인증', '외출증', '조퇴증'].includes(type1)) {
      approvalLineUpdateOutingApprovalLines().then(() => {
        setToastMsg('결재중인 확인증(확인,외출,조퇴)의 결재라인을 갱신했습니다.');
      });
    } else if (['출결신고서'].includes(type1)) {
      approvalLineUpdateabsentApprovalLines().then(() => {
        setToastMsg('결재중인 출결신고서의 결재라인을 갱신했습니다.');
      });
    } else if (['체험'].includes(type1)) {
      approvalLineUpdateFieldTripApprovalLines().then(() => {
        setToastMsg('결재중인 체험학습 서류의 결재라인을 갱신했습니다.');
      });
    }
  }

  const coachList: Array<Guide> = [
    {
      comment: <div>확인증, 출결신고서, 체험학습 신청서의 결재자를 조회/설정합니다.</div>,
      location: CoachPosition.BOTTOM,
    },
    {
      comment: <div>서류 종류를 선택합니다.</div>,
    },
    {
      comment: (
        <div>
          선택된 서류의 구분값을 선택합니다.
          <br />
          * [기본] 항목은 반드시 결재라인을 설정해주세요.
          <br />
          &nbsp;&nbsp; [기본] 외 항목의 결재라인을 따로 설정하지 않더라도 [기본] 에 설정된 결재라인이 적용됩니다.
          <br />
          <br />
          <span className="text-red-400">
            Tip 모든 확인증 구분은 담임선생님 전결인데, [보건실]은 담임,보건선생님인 경우,
            <br />
            &nbsp;&nbsp;&nbsp; [기본]는 담임선생님을 설정하고, [보건실]만 담임,보건선생님을 설정하면 돼요.
          </span>
        </div>
      ),
    },
    {
      comment: (
        <div>
          선택된 서류의 결재 단계를 선택합니다.
          <br />
          동그라미를 클릭하여 결재단계를 선택하고, 결재자 직책을 &quot;직접&quot; 입력하세요.
        </div>
      ),
    },
    {
      comment: (
        <div>
          결재 단계별 선생님을 선택해주세요.
          <br />
          담임, 학년계, 학년부장을 선택하면 해당 학급에 맞는 선생님이 자동배정 됩니다.
          <br />
          이 외 선생님은 특정선생님을 선택해주세요.
          <br />
          <span className="text-red-400">
            Tip 각 학급별 선생님을 모두 배치해야 합니다.
            <br />
            &nbsp;&nbsp;&nbsp; 전체학년에서 선택된 선생님은 모든 학급에 동일하게 배치됩니다.
            <br />
            &nbsp;&nbsp;&nbsp; 각 학년의 * 에 선택된 선생님은 해당 학년 학급에 동일하게 배치됩니다.
          </span>
        </div>
      ),
    },
    {
      comment: (
        <div>
          결재중인 서류 갱신 : 선택된 서류 중 완결되지 않은 서류의 결재라인을 다시 설정합니다.
          <br />
          <span className="text-red-400">
            * 결재중인 서류 갱신을 하지 않으면, 서류 신청 당시 지정된 결재자가 결재할 수 있습니다.
            <br />* 결재중인 서류 갱신을 하면, 이미 결재한 선생님도 다시 결재하셔야 합니다.
          </span>
          <br />
          <br />
          원래대로 : 수정한 내용을 저장하지 않고, 수정 전으로 되돌립니다.
          <br />
          <br />
          변경내용 저장 : 수정한 내용을 저장합니다. 수정사항이 발생시 활성화 됩니다.
        </div>
      ),
    },
  ];
  const { coach, refs, reOpenCoach } = useCoachMark('approvalLineAdmin', coachList);

  return (
    <>
      {<CoachMark {...coach} />}
      <Admin.Section>
        <Admin.H2>
          <div className="flex space-x-2" ref={refs[0]}>
            <span>결재라인 </span>
            <div
              className="cursor-pointer rounded-full border border-gray-500 px-2 text-sm text-gray-500"
              onClick={reOpenCoach}
            >
              ?
            </div>
          </div>
        </Admin.H2>

        <div className="flex justify-between">
          <div className="flex gap-2">
            <Select
              value={type1}
              onChange={(e) => {
                setType2('기본');
                setType1(e.target.value);
              }}
              ref={refs[1]}
            >
              {types.map((type) => (
                <option key={type.name} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Select>
            <Select value={type2} onChange={(e) => setType2(e.target.value)} ref={refs[2]}>
              {types
                .find((t) => t.name === type1)
                ?.subTypes.map((subType) => (
                  <option key={subType.name} value={subType.name}>
                    {subType.name}
                  </option>
                ))}
            </Select>
          </div>
          <div className="flex gap-2" ref={refs[5]}>
            <Button children="결재중인 서류 갱신" onClick={reSetApprovalLine} className="outlined-gray" />

            <Button
              children="원래대로"
              disabled={!modified}
              onClick={() => {
                setToastMsg('원래대로 되돌립니다.');
                setToggle((prev) => !prev);
              }}
              className="outlined-gray"
            />
            <Button
              children="변경내용 저장"
              disabled={!modified || [...titles].slice(0, enabledStep).some(([, t]) => !t)}
              onClick={save}
              className="filled-gray"
            />
          </div>
        </div>

        <div ref={refs[3]}>
          <Admin.H3>단계 선택 / 직책 직접 입력</Admin.H3>
          <div className="mt-1 grid grid-cols-[60px_repeat(5,minmax(0,1fr))] gap-1">
            <div></div>
            {steps.map((step) => (
              <Label.row key={step} htmlFor={`step-${step}`} className="h-9 rounded-md px-1 hover:bg-gray-50">
                <TextInput
                  className="h-7"
                  disabled={step > enabledStep}
                  value={titles.get(step)}
                  onChange={(e) => {
                    setTitles((prev) => new Map(prev.set(step, e.target.value)));
                    setModified(true);
                  }}
                />
                <Checkbox
                  id={`step-${step}`}
                  checked={step <= enabledStep}
                  onChange={(e) => {
                    const newStep = !e.target.checked && step === enabledStep ? Math.max(1, step - 1) : step;
                    setEnabledStep(newStep);
                    if (newStep < enabledStep) setModified(true);
                  }}
                  className="mr-1 rounded-full"
                />
              </Label.row>
            ))}
          </div>
        </div>

        <div ref={refs[4]}>
          <div>
            <Admin.H3>전체학년</Admin.H3>
          </div>
          <div className="mt-1 grid grid-cols-[60px_repeat(5,minmax(0,1fr))] gap-1">
            <div className="grid place-items-center">*</div>
            {steps.map((step) => (
              <Select
                disabled={step > enabledStep}
                value="선생님 선택"
                onChange={(e) => {
                  setTree((prev) => {
                    let teacher = teachers.find((t) => t.id === Number(e.target.value));
                    [...prev].forEach(([grade, als]) => {
                      [...als].forEach(([klass, al]) => {
                        if (e.target.value === '담임') {
                          teacher = teachers.find((t) => t.klassGroupId === al.groupId);
                        } else if (e.target.value === '학년계') {
                          teacher = teachers.find((t) => t.role === Role.PRE_HEAD && t.headNumber === grade);
                        } else if (e.target.value === '학년부장') {
                          teacher = teachers.find((t) => t.role === Role.HEAD && t.headNumber === grade);
                        }
                        if (!teacher) return;
                        prev.get(grade)?.set(klass, {
                          ...al,
                          [`approver${step}Id`]: teacher.id,
                          [`approver${step}Name`]: teacher.name,
                          [`approver${step}Role`]: teacher.role,
                        });
                      });
                    });
                    return new Map(prev);
                  });
                  setModified(true);
                }}
              >
                <option>선생님 선택</option>
                <option>담임</option>
                <option>학년계</option>
                <option>학년부장</option>
                {teachers.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name} {t.email}
                  </option>
                ))}
              </Select>
            ))}
          </div>
        </div>
        {[...tree].map(([grade, als]) => (
          <div>
            <div>
              <Admin.H3>{grade}학년</Admin.H3>
            </div>
            <div className="mt-1 grid grid-cols-[60px_repeat(5,minmax(0,1fr))] gap-1">
              <div className="grid place-items-center">*</div>
              {steps.map((step) => (
                <Select
                  disabled={step > enabledStep}
                  value="선생님 선택"
                  onChange={(e) => {
                    setTree((prev) => {
                      let teacher = teachers.find((t) => t.id === Number(e.target.value));
                      [...(prev.get(grade) ?? [])].forEach(([klass, al]) => {
                        if (e.target.value === '담임') {
                          teacher = teachers.find((t) => t.klassGroupId === al.groupId);
                        } else if (e.target.value === '학년계') {
                          teacher = teachers.find((t) => t.role === Role.PRE_HEAD && t.headNumber === grade);
                        } else if (e.target.value === '학년부장') {
                          teacher = teachers.find((t) => t.role === Role.HEAD && t.headNumber === grade);
                        }
                        if (!teacher) return;
                        prev.get(grade)?.set(klass, {
                          ...al,
                          [`approver${step}Id`]: teacher.id,
                          [`approver${step}Name`]: teacher.name,
                          [`approver${step}Role`]: teacher.role,
                        });
                      });
                      return new Map(prev);
                    });
                    setModified(true);
                  }}
                >
                  <option>선생님 선택</option>
                  <option>담임</option>
                  <option>학년계</option>
                  <option>학년부장</option>
                  {teachers.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name} {t.email}
                    </option>
                  ))}
                </Select>
              ))}
              {[...als].map(([klass, al]) => (
                <Fragment key={al.id}>
                  <div className="grid place-items-center">{klass}반</div>
                  {steps.map((step) => (
                    <Select
                      disabled={step > enabledStep}
                      value={(al as any)[`approver${step}Id`]}
                      onChange={(e) => {
                        setTree((prev) => {
                          const prevAl = prev.get(grade)?.get(klass);
                          const teacher = teachers.find((t) => t.id === Number(e.target.value));
                          if (!prevAl || !teacher) return prev;
                          prev.get(grade)?.set(klass, {
                            ...prevAl,
                            [`approver${step}Id`]: teacher.id,
                            [`approver${step}Name`]: teacher.name,
                            [`approver${step}Role`]: teacher.role,
                          });
                          return new Map(prev);
                        });
                        setModified(true);
                      }}
                    >
                      <option>선생님 선택</option>
                      {teachers.map((t) => (
                        <option key={t.id} value={t.id}>
                          {t.name} {t.email}
                        </option>
                      ))}
                    </Select>
                  ))}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </Admin.Section>
    </>
  );
}
