import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { GroupType, Role } from 'src/generated/model';

const ko = {
  translation: {
    GroupType: {
      [GroupType.KLASS]: '학급',
      [GroupType.KLUB]: '그룹',
    },
    Role: {
      [Role.ADMIN]: '관리자',
      [Role.STAFF]: '교직원',
      [Role.TEACHER]: '선생님',
      [Role.USER]: '학생',
      [Role.PRE_HEAD]: '학년계',
      [Role.HEAD]: '학년부장',
      [Role.PRE_PRINCIPAL]: '교무계',
      [Role.PRINCIPAL]: '교무부장',
      [Role.VICE_PRINCIPAL]: '교감',
      [Role.HEAD_PRINCIPAL]: '교장',
      [Role.SECURITY]: '경비선생님',
      [Role.PARENT]: '학부모',
    },
    Custom: {
      SID2: {
        domestic: '도내',
        oversea: '도외',
        absentTitle: '결석신고서', // 출결신고서
      },
      SID106: {
        // 표선고등학교
        domestic: '도내', // 국내
        oversea: '도외', // 해외
        absentTitle: '결석신고서', // 출결신고서
      },
      SID100: {
        // 제주여자상업고등학교
        domestic: '도내', // 국내
        oversea: '도외', // 해외
        absentTitle: '결석신고서', // 출결신고서
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources: { ko },
  lng: 'ko',
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
});
