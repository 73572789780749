import { useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import AnnouncementBadge from 'src/components/announcement/AnnouncementBadge';
import { BackButton, Blank, Chip, HorizontalScrollView, TopNavbar } from 'src/components/common';
import { SearchInput } from 'src/components/common/SearchInput';
import { Icon } from 'src/components/common/icons';
import { useAnnouncementByCategory } from 'src/container/announcement-category';
import { Announcement } from 'src/generated/model/announcement';
import AnnouncementDetailPage from './AnnouncementDetailPage';
import AnnouncementMobilePage from './AnnouncementMobilePage';

export default function AnnouncementPage() {
  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/announcement');
  const { push } = useHistory();
  const [searchTitle, setSearchTitle] = useState('');
  const { announcements, category, setCategory, isLoading } = useAnnouncementByCategory();
  return (
    <>
      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <TopNavbar title="슈퍼스쿨 공지사항" left={<BackButton onClick={() => push('/')} />} />
        <AnnouncementMobilePage />
      </div>

      {/* Desktop V */}
      {isLoading && <Blank reversed />}
      <div className="col-span-2 hidden h-screen md:block">
        <div className="flex place-items-center justify-between px-6 pb-3 pt-6">
          <h1 className="text-2xl font-semibold">슈퍼스쿨 공지사항</h1>
        </div>
        <div className="scroll-box h-0.5 bg-gray-100"></div>
        <HorizontalScrollView classNameInner="mt-4">
          <Chip
            children="전체"
            selected={category === undefined}
            onClick={() => setCategory(undefined)}
            className="min-w-max py-1.5"
          />
          <Chip
            children="업데이트"
            selected={category === 'UPDATE'}
            onClick={() => setCategory('UPDATE')}
            className="min-w-max py-1.5"
          />
          <Chip
            children="서비스"
            selected={category === 'SERVICE'}
            onClick={() => setCategory('SERVICE')}
            className="min-w-max py-1.5"
          />
          <Chip
            children="작업"
            selected={category === 'WORK'}
            onClick={() => setCategory('WORK')}
            className="min-w-max py-1.5"
          />
        </HorizontalScrollView>
        <div className="flex items-center space-x-2 px-6 pb-4 pt-4">
          <div className="flex w-full items-center space-x-2">
            <SearchInput
              placeholder="제목을 입력해주세요."
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="w-full"
            />
            <Icon.Search />
          </div>
        </div>
        <div className="h-screen-14 flex-1 overflow-y-scroll">
          {announcements
            ?.filter((announcement) => searchTitle === '' || announcement.title.includes(searchTitle))
            .map((item: Announcement) => <AnnouncementBadge news={item} key={item.id} type="teacher" />)}
        </div>
      </div>

      <div
        className={`scroll-box col-span-4 h-screen overflow-y-scroll bg-gray-50 p-0 md:p-6 ${
          isDetail ? 'block' : 'hidden'
        }`}
      >
        <Switch>
          <Route path="/teacher/announcement/:id" render={() => <AnnouncementDetailPage />} />
        </Switch>
      </div>
    </>
  );
}
