import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ErrorBlank } from 'src/components';
import { BackButton, Select, TopNavbar } from 'src/components/common';
import { SearchInput } from 'src/components/common/SearchInput';
import { Icon } from 'src/components/common/icons';
import { StudentItem } from 'src/components/studentCard/StudentItem';
import { GroupContainer } from 'src/container/group';
import { useTeacherSearchUser } from 'src/container/teacher-search-user';
import { useGroupsFindLectureGroupsByTeacher } from 'src/generated/endpoint';
import { GroupType, ResponseGroupDto, ResponseSearchUserDto, Role } from 'src/generated/model';
import { meState } from 'src/store';
import { StudentCardDetailPage } from './StudentCardDetailPage';

export function StudentCardPage() {
  const { pathname } = useLocation();
  const { push, replace } = useHistory();
  const me = useRecoilValue(meState);
  const { errorGroups, allKlassGroupsUnique: allKlassGroups, teacherKlassGroups } = GroupContainer.useContext();
  const { data: lectureGroups } = useGroupsFindLectureGroupsByTeacher();
  const [searchStudentName, setSearchStudentName] = useState('');

  const { userDatas, klassName, setKlassName, setKeyword, isLoading } = useTeacherSearchUser(Role.USER);
  const cardTypeMatch = pathname.match(/\/teacher\/studentcard\/\d+\/\d+\/([^/]+)/);
  const cardType = cardTypeMatch ? cardTypeMatch[1] : 'default';
  const groupIdMatch = pathname.match(/\/teacher\/studentcard\/(\d+)/);
  const groupId = groupIdMatch ? groupIdMatch[1] : me?.klassGroupId || 0;
  const isDetail = pathname !== '/teacher/studentcard' && !pathname.endsWith(`/teacher/studentcard/${groupId}`);

  const groupDatas =
    me?.role === 'TEACHER'
      ? _.chain(lectureGroups || [])
          .concat(allKlassGroups)
          .filter((el) => el.type === GroupType.KLASS)
          .uniqBy('name')
          .sortBy(['grade', 'klass'])
          .value()
      : allKlassGroups;

  useEffect(() => {
    if (!me) return;
    if (!allKlassGroups?.length) return;

    // 결정된 groupId가 있음
    if (groupId) {
      const klass = allKlassGroups.find((item: ResponseGroupDto) => item?.id === Number(groupId))?.name || '';
      setKlassName(klass);
    } else if (me.klassGroupId) {
      const klass = allKlassGroups.find((item: ResponseGroupDto) => item?.id === me.klassGroupId)?.name || '';
      setKlassName(klass);
    }
  }, [me, allKlassGroups, groupId, groupIdMatch]);

  useEffect(() => {
    if (!groupIdMatch && me?.klassGroupId) {
      replace(`/teacher/studentcard/${me.klassGroupId}`);
    }
  }, [me, groupIdMatch, replace]);

  return (
    <div className="col-span-6 md:grid md:grid-cols-11">
      {errorGroups && <ErrorBlank />}

      <div className={`col-span-3 h-screen overflow-y-auto ${isDetail && 'hidden'} md:block`}>
        <div className="md:hidden">
          <div className="block md:hidden">
            <TopNavbar title="학생정보" left={<BackButton onClick={() => push('/')} />} />
          </div>
        </div>
        <div>
          <div className="px-6 py-6">
            <h1 className="mb-5 hidden text-2xl font-semibold md:block">학생정보</h1>

            <div className="flex items-center space-x-2">
              <div className=" min-w-max cursor-pointer px-2 py-2">
                <Select.lg
                  placeholder="반 선택"
                  value={groupId}
                  onChange={(e) => {
                    replace(`/teacher/studentcard/${e.target.value}`);
                  }}
                >
                  <option defaultChecked hidden value="" className="text-gray-600">
                    {'학급 선택'}
                  </option>
                  {groupDatas.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Select.lg>
              </div>
              <div className="flex w-full items-center space-x-2">
                <SearchInput
                  placeholder="이름 검색"
                  value={searchStudentName}
                  onChange={(e) => setSearchStudentName(e.target.value)}
                  onSearch={() => setKeyword(searchStudentName)}
                  className="w-full"
                />
                <Icon.Search className="cursor-pointer" onClick={() => setKeyword(searchStudentName)} />
              </div>
            </div>
          </div>
          <div className="h-0.5 bg-gray-100"></div>
          <div className="scroll-box h-screen-15 overflow-y-auto pb-20 md:h-screen-13">
            {isLoading && <div className="text-center text-2xl text-gray-600">데이터를 불러오는 중입니다...</div>}
            {!isLoading &&
              (!groupId && (!userDatas || userDatas.length === 0) ? (
                <div className="text-center text-2xl">학급을 선택해주세요.</div>
              ) : !userDatas?.length ? (
                <div className="text-center text-2xl">검색 결과가 없거나, 권한이 없습니다.</div>
              ) : (
                userDatas
                  .filter((item) => item.klass && item.studentNumber)
                  .map((student: ResponseSearchUserDto) => (
                    <StudentItem
                      key={student?.id}
                      studentid={student?.id}
                      studentname={student?.name}
                      groupId={groupId}
                      klass={student?.klass || klassName || ''}
                      klassnum={student?.studentNumber + '번'}
                      onClick={() => replace(`/teacher/studentcard/${groupId}/${student.id}/${cardType}`)}
                    />
                  ))
              ))}
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/teacher/studentcard/:groupId/:id/:cardType" component={StudentCardDetailPage} />
      </Switch>
    </div>
  );
}
