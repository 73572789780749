import { debounce } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Checkbox, useCheckbox } from 'src/components/common/Checkbox';
import { Pagination } from 'src/components/common/Pagination';
import { SearchInput } from 'src/components/common/SearchInput';
import {
  studentManagementDeleteStudent,
  studentManagementGetStudents,
  studentManagementRequestSignUp,
  useAdminCommonFindAllKlassBySchool,
  useStudentManagementGetStudents,
} from 'src/generated/endpoint';
import { useSearch } from 'src/lib/router';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { exportCSVToExcel } from 'src/util/download-excel';
import { AdminContext } from '../AdminMainPage';

export function StudentPage() {
  const { year } = useContext(AdminContext);
  const { page, size } = useSearch({ page: 1, size: 25 });
  const [klassName, setKlassName] = useState('');
  const [firstVisit, setFirstVisit] = useState<boolean>();
  const [keyword, setKeyword] = useState('');

  const setToastMsg = useSetRecoilState(toastState);

  const { data: klasses } = useAdminCommonFindAllKlassBySchool({ year });
  const { data: students } = useStudentManagementGetStudents(
    { page, size, year, klass: klassName, first_visit: firstVisit, keyword },
    { query: { keepPreviousData: true } },
  );

  const cb = useCheckbox(students?.items);
  const ids = cb.items.map(({ id }) => id);

  async function requestSignUp() {
    if (!confirm(`사용자 ${cb.items.length}명에게 가입요청 메일을 보낼까요?`)) return;
    let sucCnt = 0;
    await Promise.all(
      ids.map((id) =>
        studentManagementRequestSignUp(id).then((result) => {
          result && sucCnt++;
        }),
      ),
    );
    setToastMsg(`가입요청 메일 전송 성공 ${sucCnt}건 / 실패 ${cb.items.length - sucCnt}건`);
    cb.clear();
  }

  async function deleteStudent() {
    if (!confirm(`항목 ${cb.items.length}개를 삭제할까요?`)) return;
    let sucCnt = 0;
    await Promise.all(
      ids.map((id) =>
        studentManagementDeleteStudent(id).then((result) => {
          result && sucCnt++;
        }),
      ),
    );
    setToastMsg(`항목 삭제 성공 ${sucCnt}건 / 실패 ${cb.items.length - sucCnt}건`);
    cb.clear();
  }

  async function downloadAsExcel() {
    const { items } = await studentManagementGetStudents({ size: 10000, year });
    const content =
      '학급,출석번호,이름,이메일,전화번호\n' +
      items
        .map((item) => [item.klassGroupName, item.studentNumber, item.name, item.email, item.phone].join())
        .join('\n');
    exportCSVToExcel(content, '학생');
  }

  function onFirstVisitChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === 'undefined') return setFirstVisit(undefined);
    if (e.target.value === 'false') return setFirstVisit(false);
    if (e.target.value === 'true') return setFirstVisit(true);
  }

  const onKeywordChange = debounce((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value), 300);

  return (
    <Admin.Section>
      <Admin.H2>학생</Admin.H2>

      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Select value={klassName} onChange={(e) => setKlassName(e.target.value)}>
            <option value="">학급 선택</option>
            {klasses
              ?.reduce((acc: any[], current: any) => {
                if (!acc.find((item) => item.id === current.id)) {
                  acc.push(current);
                }
                return acc;
              }, [])
              .map((k) => (
                <option key={k.id} value={k.name ?? ''}>
                  {k.name}
                </option>
              ))}
          </Select>
          <Select onChange={onFirstVisitChange}>
            <option value="undefined">가입여부</option>
            <option value="false">가입완료</option>
            <option value="true">가입대기</option>
          </Select>
        </div>
        <div className="flex gap-2">
          <SearchInput placeholder="검색" onChange={onKeywordChange} />
        </div>
      </div>

      <div className="flex gap-2">
        <Link children="추가" to={Routes.admin.student.new} className="button-sm outlined-gray" />
        <Link children="일괄 추가" to={Routes.admin.student.batch} className="button-sm outlined-gray" />
        <Link children="일괄 진급" to={Routes.admin.student.advance} className="button-sm outlined-gray" />
        <Button.sm
          children="가입요청 메일전송"
          title="가입여부 X 상태의 사용자만 가능합니다"
          disabled={cb.allUnchecked || cb.items.some((item) => !item.firstVisit)}
          onClick={requestSignUp}
          className="outlined-gray"
        />
        <Button.sm
          children="삭제"
          title="가입여부 X 상태의 사용자만 삭제 가능합니다"
          disabled={cb.allUnchecked || cb.items.some((item) => !item.firstVisit)}
          onClick={deleteStudent}
          className="outlined-gray"
        />
        <Button.sm children="엑셀 다운로드" onClick={downloadAsExcel} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell>
              <Checkbox checked={cb.allChecked} onChange={cb.clickAll} />
            </Admin.TableHCell>
            <Admin.TableHCell children="학급" />
            <Admin.TableHCell children="출석번호" />
            <Admin.TableHCell children="이름" />
            <Admin.TableHCell children="이메일" />
            <Admin.TableHCell children="전화번호" />
            <Admin.TableHCell children="회원바코드" />
            <Admin.TableHCell children="학적상태" />
            <Admin.TableHCell children="상태" />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {students?.items.map((student, i) => (
            <Admin.TableRow key={student.id} to={`${Routes.admin.student.index}/${student.id}`}>
              <Admin.TableCell onClick={(e) => (e.stopPropagation(), cb.click(i))}>
                <Checkbox checked={cb.checked(i)} onChange={() => cb.click(i)} />
              </Admin.TableCell>
              <Admin.TableCell children={student.klassGroupName} />
              <Admin.TableCell children={student.studentNumber} />
              <Admin.TableCell children={student.name} />
              <Admin.TableCell children={student.email} />
              <Admin.TableCell children={student.phone} />
              <Admin.TableCell children={student.barcode} />
              <Admin.TableCell children={student.expiredReason} />
              <Admin.TableCell children={student.firstVisit ? '미가입' : student.expiredReason || '재학'} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>

      <Pagination data={students} />
    </Admin.Section>
  );
}
